export function decimalColorToHexColor(color: number | string | undefined | null): string {
    if (!color) return '#000000';
    if (typeof color === 'string') {
        const hexColor = color.startsWith('#') ? color : `#${color}`;
        return hexColor;
    }
    const hexColor = `#${color.toString(16).padStart(6, '0')}`;
    return hexColor;
}

export const getContrastYIQ = (hexColor: string) => {
    if (hexColor.startsWith('#')) hexColor = hexColor.substring(1);
    const r = parseInt(hexColor.substring(0, 2), 16);
    const g = parseInt(hexColor.substring(2, 4), 16);
    const b = parseInt(hexColor.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000000' : '#ffffff';
};
