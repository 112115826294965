import React, { Dispatch } from 'react';
import { faCalendar, faHeartPulse, faTreePalm } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

type Props = {
    tabValue: number;
    setTabValue: Dispatch<React.SetStateAction<number>>;
};

const TabSelector = ({ tabValue, setTabValue }: Props) => {
    const handleOnChange = (e: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };
    const { hasFerieAccess } = useAtomValue(drawerStateAtom);
    const p = usePhrases('meny');
    return (
        <Tabs
            value={tabValue}
            onChange={handleOnChange}
            sx={{
                '& .MuiTabs-flexContainer': {
                    justifyContent: 'space-evenly',
                    width: '100%',
                    height: '72px',
                },
            }}
            variant="scrollable"
            scrollButtons="auto"
        >
            <Tab label={p('plan')} icon={<FontAwesomeIcon icon={faCalendar} size="2x" />} />

            <Tab icon={<FontAwesomeIcon icon={faHeartPulse} size="2x" />} label={p('status')} />
            {hasFerieAccess ? (
                <Tab icon={<FontAwesomeIcon icon={faTreePalm} size="2x" />} label={p('vacation')} />
            ) : (
                <Tooltip title={p('vacation_tab_no_access')}>
                    <Box>
                        <Tab label={p('vacation')} disabled icon={<FontAwesomeIcon icon={faTreePalm} size="2x" />} />
                    </Box>
                </Tooltip>
            )}
        </Tabs>
    );
};

export default TabSelector;
