import { faMoon, faSunBright, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Switch, Tooltip } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';

import { muiDark, muiLight } from '~src/muiTheme';
import { useThemeContext } from '~src/ThemeContext';

const ThemeSelectorIcon = ({ icon, bgColor, color }: { icon: IconDefinition; bgColor: string; color: string }) => {
    return (
        <Box
            width="20px"
            height="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor={bgColor}
            borderRadius="100%"
        >
            <FontAwesomeIcon icon={icon} color={color} />
        </Box>
    );
};

const ThemeSwitch = () => {
    const themeContext = useThemeContext();
    const p = usePhrases('prosjekt');

    return (
        <Tooltip title={p('theme')}>
            <Box width={'min-content'} height={'min-content'}>
                <Switch
                    sx={{
                        marginTop: '-10px',
                        marginBottom: '-10px',
                    }}
                    checked={themeContext.theme === muiDark}
                    onChange={() => themeContext.setTheme(themeContext.theme === muiDark ? muiLight : muiDark)}
                    icon={
                        <ThemeSelectorIcon
                            icon={faSunBright}
                            bgColor={themeContext.theme.palette.secondary.light}
                            color={themeContext.theme.palette.warning.light}
                        />
                    }
                    checkedIcon={
                        <ThemeSelectorIcon
                            icon={faMoon}
                            bgColor={themeContext.theme.palette.primary.main}
                            color={themeContext.theme.palette.grey[900]}
                        />
                    }
                />
            </Box>
        </Tooltip>
    );
};

export default ThemeSwitch;
