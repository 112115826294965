import { useContext } from 'react';
import { faBan, faCheck, faHourglass, faRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import TbButton from '$components/muiBien/TbButton';
import { AnsattPanelFerieEntry, OnskeStatus } from '$helpers/types';
import { FerieBehandlingContext, getOnskeStatus } from './FerieBehandlingTab';

export const GroupToggle = ({ entries, title }: { entries: AnsattPanelFerieEntry[]; title: string }) => {
    const theme = useTheme();
    const { handleOnClick, handleOnClickReset, updates } = useContext(FerieBehandlingContext);

    const propagateOnClick = (e: React.MouseEvent<HTMLElement, MouseEvent>, status: OnskeStatus) => {
        e.preventDefault();
        entries.map((entry) => {
            handleOnClick(e, {
                ferie: entry,
                status,
            });
        });
    };

    const propagateReset = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        entries.map((entry) => {
            handleOnClickReset(e, { ferie: entry, status: getOnskeStatus(entry) });
        });
    };
    return (
        <Box
            width={'100%'}
            display={'grid'}
            gridTemplateColumns={'100px 1fr'}
            gridTemplateAreas={'"dato onsk"'}
            mb={'5px'}
            minHeight={'35px'}
            borderBottom={`1px solid ${theme.palette.divider}`}
        >
            <Typography
                fontSize={12}
                fontWeight={600}
                sx={{
                    alignSelf: 'center',
                    gridColumn: 'dato',
                }}
            >
                {title}
            </Typography>
            <Box gridArea={'onsk'} alignItems={'center'} className="tri-state-toggle-wrapper">
                <ToggleButtonGroup
                    exclusive
                    sx={{
                        color: theme.palette.primary.contrastText,
                        transition: 'all 0.3s ease',
                        marginLeft: '1px',
                    }}
                >
                    <ToggleButton
                        value={OnskeStatus.Waiting}
                        sx={{
                            color: entries.every((e) => e.OnskeRequiresAction)
                                ? '#ffaa4d'
                                : theme.palette.primary.contrastText,
                            border: 'none',
                        }}
                        className="Waiting"
                        onClick={(e) => propagateOnClick(e, OnskeStatus.Waiting)}
                    >
                        <FontAwesomeIcon icon={faHourglass} />
                    </ToggleButton>
                    <Divider orientation="vertical" flexItem />
                    <ToggleButton
                        value={OnskeStatus.Rejected}
                        sx={{
                            color: entries.every((e) => e.ErOnskeAvslatt)
                                ? '#cd333b'
                                : theme.palette.primary.contrastText,
                            border: 'none',
                        }}
                        className="Rejected"
                        onClick={(e) => propagateOnClick(e, OnskeStatus.Rejected)}
                    >
                        <FontAwesomeIcon icon={faBan} />
                    </ToggleButton>
                    <Divider orientation="vertical" flexItem />
                    <ToggleButton
                        value={OnskeStatus.Approved}
                        sx={{
                            color: entries.every((e) => !e.OnskeRequiresAction && !e.ErOnskeAvslatt)
                                ? '#2d9f79'
                                : theme.palette.primary.contrastText,
                            border: 'none',
                        }}
                        className="Approved"
                        onClick={(e) => propagateOnClick(e, OnskeStatus.Approved)}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </ToggleButton>
                </ToggleButtonGroup>
                {updates.some((updated) => entries.some((entry) => updated.Id === entry.Id)) && (
                    <TbButton icon={faRotateLeft} onClick={(e) => propagateReset(e)} />
                )}
            </Box>
        </Box>
    );
};
