import { atom } from 'jotai';

type CacheKeyType = string | { employeeId: number; from: Date; to: Date };

export const isInitialLoadingAtom = atom<boolean>(true);
export const cacheKeyAtom = atom<CacheKeyType[]>([]);
export const yearAtom = atom<number>(new Date().getFullYear());
export const from = atom<Date>(new Date());
export const to = atom<Date>(new Date());

export const ferieTabStateAtom = atom(
    (get) => {
        return {
            isInitialLoading: get(isInitialLoadingAtom),
            cacheKey: get(cacheKeyAtom),
            year: get(yearAtom),
            from: get(from),
            to: get(to),
        };
    },
    (
        _,
        set,
        update: Partial<{
            isInitialLoading: boolean;
            cacheKey: CacheKeyType[];
            year: number;
            from: Date;
            to: Date;
        }>
    ) => {
        if (update.isInitialLoading !== undefined) set(isInitialLoadingAtom, update.isInitialLoading);
        if (update.cacheKey !== undefined) set(cacheKeyAtom, update.cacheKey);
        if (update.year !== undefined) set(yearAtom, update.year);
        if (update.from !== undefined) set(from, update.from);
        if (update.to !== undefined) set(to, update.to);
    }
);
