import { useEffect, useState } from "react";
import { getPhrases } from "./api";
// Cache for created p functions
var p_cache = {};
export function usePhrases(domain) {
    // First, check if p function has already been created
    var cached_p = p_cache[domain];
    // Initialize with the cached or mock version of p
    var _a = useState(function () { return (cached_p !== null && cached_p !== void 0 ? cached_p : mock_p); }), p = _a[0], setP = _a[1];
    useEffect(function () {
        // If p is mock_p, get translation from API
        if (p === mock_p) {
            getPhrases(domain)
                .then(function (translation) {
                // Generate p function
                var new_p = p_gen(translation);
                // Write to cache
                p_cache[domain] = new_p;
                // Update p
                setP(function () { return new_p; });
            })
                .catch(function (err) {
                console.error("PHRASE_ERROR:", err);
                // TODO: Default to some special version of p
            });
        }
    }, [domain]);
    return p;
}
;
// "phrases" function that returns empty string
function mock_p(descriptor) {
    var replacements = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        replacements[_i - 1] = arguments[_i];
    }
    return "";
}
// Generates "phrases" function
function p_gen(translation) {
    return function (descriptor) {
        var _a;
        var replacements = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            replacements[_i - 1] = arguments[_i];
        }
        var phrase = translation.phrases[descriptor];
        if (phrase === undefined) {
            console.warn("PHRASE_WARNING:", "Phrase \"" + descriptor + "\" not found in domain \"" + translation.domain + "\"");
            return ""; // Maybe error message?
        }
        if (replacements.length) {
            var numMatches = ((_a = phrase.match(/<\*>/g)) === null || _a === void 0 ? void 0 : _a.length) || 0;
            if (numMatches !== replacements.length) {
                var replacementsStr = replacements
                    .map(function (_) { return "\"" + _ + "\""; })
                    .join(", ");
                console.warn("PHRASE_WARNING:", "Invalid replacements for \"" + phrase + "\": [" + replacementsStr + "]");
            }
            for (var _b = 0, replacements_1 = replacements; _b < replacements_1.length; _b++) {
                var replacement = replacements_1[_b];
                phrase = phrase.replace(/<\*>/, replacement);
            }
        }
        return phrase;
    };
}
