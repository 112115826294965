import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getFerieInfo } from '$helpers/api';

type Props = {
    databaseId: string;
    employeeId: number;
    from: Date;
    to: Date;
};

export const rootCacheKey = ['ferieInfo'];

const useFerieInfo = ({ databaseId, employeeId, from, to }: Props) => {
    const cacheKey = [...rootCacheKey, { databaseId, employeeId, from, to }];

    const { data, isLoading, isFetching } = useQuery({
        queryKey: cacheKey,
        queryFn: () => getFerieInfo(employeeId, from, to),
        enabled: true,
        refetchOnMount: 'always',
        placeholderData: keepPreviousData,
    });

    const ferieInfo = data ?? [];

    return {
        ferieInfo,
        isLoading: isLoading || isFetching,
        cacheKey,
    };
};

export default useFerieInfo;
