import { faExternalLinkAlt, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken, lighten, Link, Typography, useTheme } from '@mui/material';

type validColors = 'primary' | 'secondary' | 'error' | 'warning' | 'success';
export const LinkWithIcon = ({
    href,
    linkText,
    customIcon = faExternalLinkAlt,
    iconFirst = false,
    noIcon = false,
    bgColor = 'primary',
    disabled = false,
}: {
    href: string;
    linkText: string;
    customIcon?: IconDefinition;
    iconFirst?: boolean;
    noIcon?: boolean;
    bgColor?: validColors;
    disabled?: boolean;
}) => {
    const theme = useTheme();
    return (
        <>
            <Link
                fontSize={'14px'}
                fontWeight={800}
                href={disabled ? undefined : href}
                display="flex"
                alignItems="center"
                justifyContent={iconFirst ? 'start' : 'space-between'}
                padding={'5px'}
                borderRadius={'5px'}
                color={disabled ? theme.palette.grey[400] : theme.palette[bgColor].main}
                height={'28px'}
                bgcolor={
                    disabled
                        ? theme.palette.grey[100]
                        : theme.palette.mode === 'light'
                        ? lighten(theme.palette[bgColor].main, 0.9)
                        : darken(theme.palette[bgColor].dark, 0.6)
                }
                sx={{
                    cursor: disabled ? 'default' : 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        bgcolor: disabled
                            ? theme.palette.grey[100]
                            : theme.palette.mode === 'light'
                            ? lighten(theme.palette[bgColor].main, 0.8)
                            : darken(theme.palette[bgColor].dark, 0.4),
                        color: disabled ? theme.palette.grey[400] : theme.palette[bgColor].main,
                    },
                }}
            >
                {iconFirst ? (
                    <>
                        {!noIcon && <FontAwesomeIcon icon={customIcon} size="sm" />}
                        <Typography fontSize={'12px'} fontWeight={700} ml={iconFirst ? '10px' : 0}>
                            {linkText}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography fontSize={'12px'} fontWeight={700}>
                            {linkText}
                        </Typography>
                        {!noIcon && <FontAwesomeIcon icon={customIcon} size="sm" />}
                    </>
                )}
            </Link>
        </>
    );
};
