import { ReactNode } from "react";
import { Box, Divider, Typography } from "@mui/material";

type Props = {
    title?: ReactNode;
    children?: ReactNode;
}

const TreeBranch = ({title, children}: Props) => {
    return (
        <Box>
            <Typography variant="subtitle1" fontWeight={children ? 400 : 900}>{title}</Typography>

            {children && (
                <Box display="flex" alignItems="flex-start" gap={2.5}>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" flexDirection="column" gap={1}>
                        {children}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default TreeBranch;