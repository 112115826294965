import { useQuery } from '@tanstack/react-query';
import { Fridag } from '$helpers/types';
import { getHolidays } from '../helpers/api';

export const rootCacheKey = ['fridagInfo'];

type Props = {
    databaseId: string;
    employeeId: number;
    from: Date;
    to: Date;
};

type ReturnType = {
    fridager: Fridag[];
    isLoading: boolean;
    isInitialLoading: boolean;
    cacheKey: (string | { databaseId: string; employeeId: number; from: Date; to: Date })[];
};
const useHolidays = ({ databaseId, employeeId, from, to }: Props): ReturnType => {
    const cacheKey = [...rootCacheKey, { databaseId, employeeId, from, to }];

    const { data, isLoading, isFetching, isInitialLoading } = useQuery(
        cacheKey,
        async () => await getHolidays(from, to),
        {
            enabled: true,
            keepPreviousData: true,
            refetchOnMount: 'always',
        }
    );
    const fridager = data ?? [];

    return {
        fridager,
        isLoading: isLoading || isFetching,
        isInitialLoading,
        cacheKey,
    };
};

export default useHolidays;
