import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { setDefaultOptions } from 'date-fns';
import { Provider } from 'jotai';
import useDateLocale from '$hooks/use-dateLocale';
import EmployeeDrawer from './components/EmployeeDrawer';
import { ThemeContextProvider } from './ThemeContext';

const App = () => {
    const tbLocale = useDateLocale();
    setDefaultOptions({ locale: tbLocale, firstWeekContainsDate: 4, weekStartsOn: 1 });
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false,
                staleTime: 1000 * 60 * 5,
                retry(failureCount, error) {
                    const castedError = error as Error;
                    if (castedError.message === '401') {
                        return false;
                    }

                    return failureCount < 2;
                },
            },
        },
    });

    return (
        <Provider>
            <ThemeContextProvider>
                <QueryClientProvider client={queryClient}>
                    <EmployeeDrawer />
                </QueryClientProvider>
            </ThemeContextProvider>
        </Provider>
    );
};

export default App;
