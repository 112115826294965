import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { AnsattPanelFerieEntry } from '$helpers/types';
import { DateText } from '../DateText';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const FerieStatusDataRow = ({ ferie }: { ferie: AnsattPanelFerieEntry }) => {
    const theme = useTheme();
    const p = usePhrases('meny');
    const updateTransition = 'all 0.25s ease-in-out';
    const { employee } = useAtomValue(drawerStateAtom);
    return ferie.ErOnskeAvslatt ? (
        <Box
            width={'100%'}
            display={'grid'}
            gridTemplateColumns={'110px repeat(3, 1fr)'}
            gridTemplateAreas={'"dato onsk plan reg"'}
            sx={{ gap: '8px', backgroundColor: theme.palette.error.light, color: '#181f25', cursor: 'pointer' }}
            borderBottom={`1px solid ${theme.palette.divider}`}
            onClick={() => {
                window.open(
                    `https://${location.hostname}/plan?visning=uke&dato=${format(
                        new Date(ferie.Date),
                        'yyyy-MM-dd'
                    )}&ferie=true&ansatt=${employee?.Id}&avdeling=${employee?.AvdelingId}`,
                    '_self'
                );
            }}
        >
            <DateText ferie={ferie} />
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridColumn={'2 / -1'}>
                <Typography padding={'8px'} fontSize={14} fontWeight={400} color={'inherit'}>
                    {p('request_declined')}
                </Typography>
            </Box>
        </Box>
    ) : (
        <Box
            width={'100%'}
            display={'grid'}
            gridTemplateColumns={'110px repeat(3, 1fr)'}
            gridTemplateAreas={'"dato onsk plan reg"'}
            sx={{
                gap: '8px',
                cursor: 'pointer',
            }}
            borderBottom={`1px solid ${theme.palette.divider}`}
            onClick={() => {
                window.open(
                    `https://${location.hostname}/plan?visning=uke&dato=${format(
                        new Date(ferie.Date),
                        'yyyy-MM-dd'
                    )}&ferie=true&ansatt=${employee?.Id}&avdeling=${employee?.AvdelingId}`,
                    '_self'
                );
            }}
        >
            <DateText ferie={ferie} />
            <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'onsk'} padding={'8px'}>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    title={
                        ferie.ErOnsket
                            ? ferie.OnskeRequiresAction
                                ? p('vacationrequest_yellow')
                                : p('vacationrequest_green')
                            : p('vacationrequest_white')
                    }
                >
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: ferie.ErOnsket
                                ? ferie.OnskeRequiresAction
                                    ? '#f8a943'
                                    : '#429f78'
                                : '#fff',
                            border: '1px solid #4e4d48',
                            transition: updateTransition,
                        }}
                    />
                </Tooltip>
            </Box>
            <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'plan'}>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    title={
                        ferie.ErPlanlagt
                            ? p('vacationplanned_green')
                            : ferie.PlanlagtRequiresAction
                            ? p('vacationplanned_yellow')
                            : p('vacationplanned_white')
                    }
                >
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: ferie.ErPlanlagt
                                ? '#429f78'
                                : ferie.PlanlagtRequiresAction
                                ? '#f8a943'
                                : '#fff',
                            border: '1px solid #4e4d48',
                            transition: updateTransition,
                        }}
                    />
                </Tooltip>
            </Box>
            <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'reg'}>
                <Tooltip
                    PopperProps={{ disablePortal: true }}
                    title={
                        ferie.ErBehandlet
                            ? p('vacationregistered_green')
                            : ferie.ErPlanlagt
                            ? p('vacationregistered_yellow')
                            : p('vacationregistered_white')
                    }
                >
                    <Box
                        sx={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: ferie.ErBehandlet ? '#429f78' : ferie.ErPlanlagt ? '#f8a943' : '#fff',
                            border: '1px solid #4e4d48',
                            transition: updateTransition,
                        }}
                    />
                </Tooltip>
            </Box>
        </Box>
    );
};
