import { ReactNode } from 'react';
import { Box, Divider, SxProps, Typography } from '@mui/material';

type Props = {
    title?: ReactNode;
    children?: ReactNode;
    sx?: SxProps;
};

const TreeBranch = ({ title, children, sx }: Props) => {
    return (
        <Box sx={{ ...sx }}>
            <Typography variant="subtitle1" fontWeight={children ? 400 : 900}>
                {title}
            </Typography>

            {children && (
                <Box display="flex" alignItems="flex-start" gap={2.5}>
                    <Divider orientation="vertical" flexItem />
                    <Box display="flex" flexDirection="column" gap={1}>
                        {children}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default TreeBranch;
