import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { FerieContext } from './FerieTab';

export const FerieInfoText = () => {
    const { remainingFerie } = useContext(FerieContext);
    const p = usePhrases('meny');
    return (
        <>
            <Typography fontSize={14} fontWeight={800}>
                {p('total_this_year')}
            </Typography>
            <Box
                width={'100%'}
                height={'min-content'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography fontSize={12} fontWeight={600}>
                    {p('waiting_vacation_for_approval')}
                </Typography>
                <Typography fontSize={12} fontWeight={900}>
                    {remainingFerie?.PendingVacationRequestsThisYear?.Count ?? 0}
                </Typography>
            </Box>
            <Box
                width={'100%'}
                height={'min-content'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography fontSize={12} fontWeight={600}>
                    {p('approved_vacation_request')}
                </Typography>
                <Typography fontSize={12} fontWeight={900}>
                    {remainingFerie?.PlannedVacationDaysThisYear?.Count ?? 0}
                </Typography>
            </Box>
            <Box
                width={'100%'}
                height={'min-content'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
            >
                <Typography fontSize={12} fontWeight={600}>
                    {p('remaining_vacation')}
                </Typography>
                <Typography fontSize={12} fontWeight={900}>
                    {remainingFerie?.RemainingVacationDaysThisYear?.Count ?? 0}
                </Typography>
            </Box>
        </>
    );
};
