import { atom } from 'jotai';
import { atomWithQuery } from 'jotai-tanstack-query';
import { getEmployee, getMe } from '$helpers/api';
import { rootCacheKey } from '$hooks/useFerieInfo';

export enum TabsEnum {
    Plan = 0,
    Status = 1,
    Ferie = 2,
    Logout = 3,
}

export const fullViewAtom = atom<boolean>(false);
export const isOpenAtom = atom<boolean>(false);
export const openTabAtom = atom<TabsEnum>(TabsEnum.Status);
export const employeeIdAtom = atom<number | null>(null);
export const hasAdminOrAvdLederAtom = atom<boolean>(false);
export const hasFerieAccessAtom = atom<boolean>(false);
export const databaseId = atom<string>('');
export const unsavedChangesAtom = atom<boolean>(false);

export const drawerStateAtom = atom(
    (get) => {
        const employeeRes = get(employeeAtom);
        const employee = employeeRes.data ? employeeRes.data : null;

        const meRes = get(meAtom);
        const me = meRes.data ? meRes.data : null;
        return {
            databaseId: get(databaseId),
            fullView: get(fullViewAtom),
            isOpen: get(isOpenAtom),
            openTab: get(openTabAtom),
            employeeId: get(employeeIdAtom),
            employee,
            me,
            hasAdminOrAvdLederAccess: get(hasAdminOrAvdLederAtom),
            hasFerieAccess: get(hasFerieAccessAtom),
            unsavedChangesAtom: get(unsavedChangesAtom),
        };
    },
    (
        _,
        set,
        update: Partial<{
            databaseId: string;
            fullView: boolean;
            isOpen: boolean;
            openTab: TabsEnum;
            employeeId: number | null;
            hasAdminOrAvdLederAccess: boolean;
            hasFerieAccess: boolean;
            unsavedChanges: boolean;
        }>
    ) => {
        if (update.databaseId !== undefined) set(databaseId, update.databaseId);
        if (update.fullView !== undefined) set(fullViewAtom, update.fullView);
        if (update.isOpen !== undefined) set(isOpenAtom, update.isOpen);
        if (update.openTab !== undefined) set(openTabAtom, update.openTab);
        if (update.employeeId !== undefined) set(employeeIdAtom, update.employeeId);
        if (update.hasAdminOrAvdLederAccess !== undefined) set(hasAdminOrAvdLederAtom, update.hasAdminOrAvdLederAccess);
        if (update.hasFerieAccess !== undefined) set(hasFerieAccessAtom, update.hasFerieAccess);
        if (update.unsavedChanges !== undefined) set(unsavedChangesAtom, update.unsavedChanges);
    }
);

export const employeeAtom = atomWithQuery((get) => {
    const employeeId = get(employeeIdAtom);
    const dbId = get(databaseId);
    return {
        queryKey: [rootCacheKey, dbId, 'employee', employeeId],
        queryFn: async () => {
            if (!employeeId || !dbId) return null;
            return await getEmployee(employeeId);
        },
        refetchOnMount: 'always',
        staleTime: 1000 * 60 * 5,
    };
});

export const meAtom = atomWithQuery(() => {
    return {
        queryKey: [rootCacheKey, 'me'],
        queryFn: async () => {
            return await getMe();
        },
        refetchOnMount: 'always',
        staleTime: 1000 * 60 * 5,
    };
});
