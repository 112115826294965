export const formatNumber = (
    value?: number | null,
    minDecimals = 2,
    maxDecimals = 2,
    showNoDecimalsPostfix = false
) => {
    if (value === undefined || value === null) {
        return '';
    }

    if (Number.isInteger(value)) {
        return value.toLocaleString('nb-NO') + (showNoDecimalsPostfix ? ',-' : '');
    }

    return value.toLocaleString('nb-NO', {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    });
};
