import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import {
    faDownLeftAndUpRightToCenter,
    faEnvelope,
    faUpRightAndDownLeftFromCenter,
    faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Link, Skeleton, Tooltip, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue, useSetAtom } from 'jotai';
import { logOut } from '$helpers/api';
import useIsMobile from '$hooks/useIsMobile';
import TbButton from '../muiBien/TbButton';
import ThemeSwitch from '../ThemeSwitch';
import { AvatarOrInitials } from './AvatarOrInitials';

import { drawerStateAtom, fullViewAtom } from '~src/contextsAndAtoms/DrawerAtoms';

type Props = {
    close: () => void;
};

const tbUrl =
    location.hostname === 'min.tidsbanken.net' ? 'https://min.tidsbanken.net' : 'https://sandkasse.tidsbanken.net';

const EmployeeInfo = ({ close }: Props) => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const { employeeId, fullView, employee, me, hasAdminOrAvdLederAccess } = useAtomValue(drawerStateAtom);
    const setFullView = useSetAtom(fullViewAtom);

    const isMe = me && employeeId === me?.Id;
    const p = usePhrases('meny');

    const handleSendMessageClick = () => {
        const sendMessageUrl = `${tbUrl}/sendMelding?til=${employeeId}`;
        window.open(sendMessageUrl, '_blank', 'noopener,noreferrer');
    };

    const handleLogOutClick = async () => {
        await logOut();
    };

    const almostBlack = '#29343d';

    return (
        <Box>
            <Box bgcolor={almostBlack} display="flex" alignItems="center" justifyContent="space-between" p={0}>
                {location.hostname !== 'min.tidsbanken.net' && !isMobile && (
                    <TbButton
                        sx={{ color: 'white', m: '0.5rem' }}
                        icon={fullView ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
                        onClick={() => setFullView(!fullView)}
                    />
                )}
                <Typography color="white" px={2} fontSize="1.2rem">
                    {!employee ? (
                        <Skeleton width="200px" sx={{ bgcolor: 'grey.400' }} />
                    ) : (
                        <>
                            {employeeId} {employee?.Fornavn} {employee?.Etternavn}
                        </>
                    )}
                </Typography>
                <TbButton sx={{ color: 'white', m: '0.5rem' }} icon={faXmarkLarge} onClick={close} />
            </Box>

            <Box display="flex" flexDirection="row" alignItems="center" gap={0.5} p={2}>
                <AvatarOrInitials
                    imageUrl={employee?.Bilde}
                    firstName={employee?.Fornavn}
                    lastName={employee?.Etternavn}
                />
                <Box display={'flex'} flexDirection={'column'}>
                    <Typography variant="h4" fontWeight={600} fontSize={'1rem'}>
                        {employee?.Fornavn} {employee?.Etternavn}
                        {hasAdminOrAvdLederAccess && (
                            <Tooltip arrow title={p('go_to_employee_profile')}>
                                <Link
                                    href={`${tbUrl}/kartotek_ansatte.asp?id=${employeeId}#personalia`}
                                    target="_self"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <IconButton size={'small'}>
                                        <FontAwesomeIcon
                                            icon={faArrowUpRightFromSquare}
                                            color={theme.palette.primary.main}
                                            size={'xs'}
                                            style={{ marginTop: '-3px' }}
                                        />
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        )}
                    </Typography>
                    <Typography fontWeight={500} fontSize={'0.8rem'}>
                        {employee?.AvdelingNavn}
                    </Typography>
                    <Typography fontWeight={900} fontSize={'0.9rem'}>
                        <a href={'tel:' + employee?.Mobil}>{employee?.Mobil}</a>
                    </Typography>
                    <Typography fontWeight={900} fontSize={'0.9rem'}>
                        <a href={'mailto:' + employee?.Epost}>{employee?.Epost}</a>
                    </Typography>
                </Box>
            </Box>

            {isMe ? (
                <Box
                    px={2}
                    py={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                    bgcolor={almostBlack}
                >
                    <Link
                        color="#fff"
                        sx={{
                            '&:hover': {
                                color: '#fff',
                            },
                        }}
                        fontWeight={900}
                        href={`${tbUrl}/ansatte_profil.asp`}
                    >
                        {p('top_drop_profile') ? p('top_drop_profile') : 'Min profil'}
                    </Link>
                    {location.hostname !== 'min.tidsbanken.net' && <ThemeSwitch />}

                    <Link color="#fff" fontWeight={900} component="button" onClick={handleLogOutClick}>
                        {p('top_drop_logout') ? p('top_drop_logout') : 'Logg ut'}
                    </Link>
                </Box>
            ) : (
                <Box
                    px={1}
                    py={1}
                    height={'36px'}
                    width={'100%'}
                    display="flex"
                    justifyContent="start"
                    alignItems={'center'}
                    bgcolor={almostBlack}
                    color={'#fff'}
                    sx={{
                        '&:hover': {
                            color: 'primary.light',
                            cursor: 'pointer',
                        },
                    }}
                >
                    <TbButton
                        variant="text"
                        size="large"
                        icon={faEnvelope}
                        color="inherit"
                        onClick={handleSendMessageClick}
                    />
                    <Typography
                        variant="inherit"
                        sx={{
                            transition: 'color 0.3s',
                            textDecorationLine: 'underline',
                        }}
                        fontWeight={900}
                        fontSize={'14px'}
                        onClick={handleSendMessageClick}
                    >
                        {p('send_msg_emp_drawer') ? p('send_msg_emp_drawer') : 'Send melding'}
                    </Typography>
                </Box>
            )}
        </Box>
    );
};
export default EmployeeInfo;
