import { useQuery } from "@tanstack/react-query";
import { getTodaysShifts } from "$helpers/api";

type Props = {
    employeeId: number;
    enabled: boolean;
};

const rootCacheKey = ['todaysShifts'];

const useTodaysShifts = ({employeeId, enabled}: Props) => {
    const cacheKey = [...rootCacheKey, {employeeId}];

    const {data, isLoading, isFetching} = useQuery(cacheKey, () => getTodaysShifts(employeeId), {
        enabled,
    });

    const todaysShifts = data ?? [];

    return {
        todaysShifts,
        isLoadingTodaysShifts: isLoading || isFetching,
    };
};

export default useTodaysShifts;