import { useContext } from 'react';
import { Box, capitalize, Typography, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { AnsattPanelFerieEntry } from '$helpers/types';
import { FerieContext } from './FerieTab';

export const DateText = ({ ferie, color }: { ferie: AnsattPanelFerieEntry; color?: string }) => {
    const { fridager } = useContext(FerieContext);
    const theme = useTheme();
    if (!ferie.Date) return <></>;

    const parsedDate = parseISO(ferie.Date);
    const day = capitalize(format(parsedDate, 'eee'));
    const date = format(parsedDate, 'dd.MM');

    let isHolidayToday = false;
    if (fridager?.some((fridag) => fridag.IsHoliday && format(parseISO(fridag.Date), 'dd.MM') === date)) {
        isHolidayToday = true;
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            gridArea={'dato'}
            sx={{ color: color ? color : isHolidayToday ? theme.palette.error.main : 'inherit' }}
        >
            <Typography fontSize={14} fontWeight={800} color="inherit">
                {day}
            </Typography>
            <Typography fontSize={14} fontWeight={800} mr={3} color="inherit">
                {date}
            </Typography>
        </Box>
    );
};
