import { Box } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';

export const FerieInnstillingerTab = () => {
    const p = usePhrases('meny');
    return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} width={'100%'} mt={'10px'}>
            <i>{p('settings_not_implemented')}</i>
        </Box>
    );
};
