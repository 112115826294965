import { useState } from 'react';
import { faRightFromBracket, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, lighten, Link, Popover, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import { logOut } from '$helpers/api';
import { baseUrl } from '$helpers/helpers';
import { AvatarOrInitials } from './AvatarOrInitials';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const AvatarDropdown = () => {
    const { employee, me } = useAtomValue(drawerStateAtom);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const theme = useTheme();
    const p = usePhrases('meny');
    const isMe = employee?.Id === me?.Id;
    return (
        <>
            <AvatarOrInitials
                imageUrl={employee?.Bilde}
                firstName={employee?.Fornavn}
                lastName={employee?.Etternavn}
                innstemplet={employee?.Innstemplet}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)}
                sx={{
                    cursor: isMe ? 'pointer' : 'default',
                }}
            />
            {isMe && (
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    slotProps={{
                        paper: {
                            sx: {
                                ml: '-10px',
                                mt: '6px',
                            },
                        },
                    }}
                >
                    <Box
                        px={1.2}
                        py={1}
                        sx={{
                            bgcolor: theme.palette.background.paper,
                            borderRadius: '4px 4px 0px 0px',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: theme.palette.action.hover,
                            },
                        }}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        component={Link}
                        href={`${baseUrl}/hjem/profil`}
                    >
                        <Typography fontSize={'12px'}>{p('my_profile')}</Typography>
                        <FontAwesomeIcon style={{ marginLeft: '8px' }} size="sm" icon={faUser} />
                    </Box>
                    <Divider />
                    <Box
                        px={1.2}
                        py={1}
                        sx={{
                            bgcolor: theme.palette.error.light,
                            borderRadius: '0px 0px 4px 4px',
                            cursor: 'pointer',
                            '&:hover': {
                                bgcolor: lighten(theme.palette.error.main, 0.8),
                            },
                        }}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                        onClick={async () => {
                            await logOut();
                        }}
                    >
                        <Typography fontSize={'12px'} color={theme.palette.error.main}>
                            {p('logout')}
                        </Typography>
                        <FontAwesomeIcon
                            style={{ marginLeft: '8px' }}
                            size="sm"
                            icon={faRightFromBracket}
                            color={theme.palette.error.main}
                        />
                    </Box>
                </Popover>
            )}
        </>
    );
};
