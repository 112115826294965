import { createTheme, ThemeOptions } from '@mui/material';

const muiLightTheme: ThemeOptions = {
    typography: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbf8f4',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: '20rem',
                    backgroundImage: 'none',
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                input: {
                    margin: 0,
                },
            },
        },
    },
    palette: {
        mode: 'light',

        primary: {
            main: '#3878c7',
            dark: '#2d609f',
            light: '#afc9e9',
            contrastText: '#000000',
        },
        secondary: {
            main: '#3d4e5c',
            dark: '#141a1f',
            light: '#668299',
            contrastText: '#ffffff',
        },
        success: {
            main: '#2d9f79',
            dark: '#1c634c',
            light: '#9ce3cb',
            contrastText: '#ffffff',
        },
        error: {
            main: '#cd333b',
            dark: '#a3292f',
            light: '#faebeb',
            contrastText: '#ffffff',
        },
        warning: {
            main: '#e67700',
            dark: '#ff901a',
            light: '#ffdab3',
            contrastText: '#1e1e1e',
        },
        info: {
            main: '#808080',
            light: '#f0f2f2',
        },
        flytbar: {
            main: '#ebeef1',
        },
        sidebarBackground: {
            main: '#f0f2f2',
            light: '#ffffff',
        },
        app: {
            main: '#fbf8f4',
        },
        offer: {
            main: '#7D26CD',
        },
        chart: {
            blue: '#3878c7',
            red: '#cd333b',
            green: '#2d9f79',
            yellow: '#e67700',
            purple: '#7D26CD',
            turquoise: '#00a6a6',
            pink: '#ff80ab',
            orange: '#ff901a',
            grey: '#808080',
            blue2: '#afc9e9',
            red2: '#e6999d',
            green2: '#9ce3cb',
            yellow2: '#ffdab3',
            purple2: '#b48fcd',
            turquoise2: '#9fd3d3',
            pink2: '#ffc0cb',
            orange2: '#ffdab3',
            grey2: '#f0f2f2',
        },

        border: {
            main: '#e5e9ed',
        },
        inputBackground: {
            main: '#fff',
        },
        stoppedCardBackground: {
            main: '#fff2dc9e',
        },
        tableRow: {
            odd: '#ebeef1',
            even: '#f2f2f2',
        },
    },
};

export const muiLight = createTheme(muiLightTheme);

const muiDarkTheme: ThemeOptions = {
    ...muiLightTheme,

    typography: {
        ...muiLightTheme.typography,

        allVariants: {
            ...muiLightTheme.typography,

            color: '#fff',
        },
    },
    palette: {
        ...muiLightTheme.palette,

        mode: 'dark',

        background: {
            default: '#181F25', //'#101519',
            paper: '#222E39', // '#29333C',  //'#181F25',
        },
        primary: {
            main: '#3878c7',
            dark: '#2d609f',
            light: '#afc9e9',
            contrastText: '#ffffff',
        },
        error: {
            main: '#cd333b',
            dark: '#a3292f',
            light: '#f0c0c0',
            contrastText: '#ffffff',
        },

        warning: {
            main: '#e67700',
            dark: '#ff901a',
            light: '#ffdab3',
            contrastText: '#484848',
        },
        sidebarBackground: {
            main: '#181f25',
            light: '#27323b',
        },
        flytbar: {
            main: '#1a2632',
        },
        app: {
            main: '#181f25',
        },
        offer: {
            main: '#7D26CD',
        },
        chart: {
            blue: '#3878c7',
            red: '#cd333b',
            green: '#2d9f79',
            yellow: '#e67700',
            purple: '#7D26CD',
            turquoise: '#00a6a6',
            pink: '#ff80ab',
            orange: '#ff901a',
            grey: '#808080',
            blue2: '#afc9e9',
            red2: '#e6999d',
            green2: '#9ce3cb',
            yellow2: '#ffdab3',
            purple2: '#b48fcd',
            turquoise2: '#9fd3d3',
            pink2: '#ffc0cb',
            orange2: '#ffdab3',
            grey2: '#f0f2f2',
        },
        border: {
            main: '#3B4752',
        },
        inputBackground: {
            main: '#101519',
        },
        stoppedCardBackground: {
            main: '#3a260f',
        },
        tableRow: {
            odd: '#181F25',
            even: '#101519',
        },
    },
};

export const muiDark = createTheme(muiDarkTheme);
