import { useQuery } from "@tanstack/react-query";

import { getClockedInHourLines } from "~src/helpers/api";

type Props = {
    employeeId: number;
    enabled: boolean;
}

export const rootCacheKey = ['clockedInHourLine'];

const useClockedInHourLine = ({employeeId, enabled}: Props) => {
    const cacheKey = [...rootCacheKey, {
        employeeId,
    }];

    const {data, isLoading, isFetching} = useQuery(cacheKey, () => getClockedInHourLines(employeeId), {
        enabled: enabled && employeeId > 0,
    });

    const clockedInHourLine = data?.[0] ?? null;

    return {
        clockedInHourLine: clockedInHourLine,
        isLoadingClockedInHourLine: isLoading || isFetching,
    }
};

export default useClockedInHourLine;