import { usePhrases } from '@tidsbanken/phrases';
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';
import locale from 'date-fns/locale/nb/index.js';
const useDateLocale = () => {
    const dt = usePhrases('datetime');

    const _months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'des'].map((m) =>
        dt(`m_${m}`)
    );
    const _days = ['sun', 'mon', 'tue', 'wen', 'thu', 'fri', 'sat'].map((m) => dt(`d_${m}`));

    const monthValues = {
        narrow: _months.map((_) => _.substring(0, 1).toUpperCase()),
        abbreviated: _months.map((_) => _.substring(0, 3)),
        wide: _months,
    };
    const dayValues = {
        narrow: _days.map((_) => _.substring(0, 1).toUpperCase()),
        short: _days.map((_) => _.substring(0, 2)),
        abbreviated: _days.map((_) => _.substring(0, 3)),
        wide: _days,
    };

    return {
        ...locale,
        localize: {
            ...locale.localize,
            month: buildLocalizeFn({
                values: monthValues,
                defaultWidth: 'wide',
            }),
            day: buildLocalizeFn({
                values: dayValues,
                defaultWidth: 'wide',
            }),
        },
    } as Locale;
};

export default useDateLocale;
