// Utility function for debouncing, with TypeScript annotations
export function debounce(
    func: (...args: unknown[]) => void,
    wait: number,
    immediate?: boolean
): (...args: unknown[]) => void {
    let timeout: NodeJS.Timeout | null = null;
    return (...args: unknown[]) => {
        const later = () => {
            timeout = null;
            if (!immediate) func(...args);
        };
        const callNow = immediate && !timeout;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func(...args);
    };
}
