import { faEnvelope, faEnvelopesBulk, faPhone } from '@fortawesome/pro-solid-svg-icons';
import { Box, Divider, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import { LinkWithIcon } from './LinkWithIcon';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const ContactInfo = () => {
    const p = usePhrases('meny');
    const { employee, employeeId, me } = useAtomValue(drawerStateAtom);
    const baseUrl =
        window.location.hostname === 'min.tidsbanken.net'
            ? 'https://min.tidsbanken.net'
            : 'https://sandkasse.tidsbanken.net';

    const sendMessageUrl = new URL(`/sendmelding?til=${employeeId}`, baseUrl).toString();
    return (
        <Box display="flex" flexDirection="column" margin={1} gap={1}>
            <LinkWithIcon
                href={`tel:${employee?.Mobil}`}
                linkText={employee?.Mobil ? employee.Mobil : p('missing_phone')}
                customIcon={faPhone}
                iconFirst
                disabled={!employee?.Mobil}
            />
            <LinkWithIcon
                href={`mailto:${employee?.Epost}`}
                linkText={employee?.Epost ? employee.Epost : p('missing_email')}
                customIcon={faEnvelope}
                iconFirst
                disabled={!employee?.Epost}
            />
            <LinkWithIcon href={sendMessageUrl} linkText={p('tb_message')} customIcon={faEnvelopesBulk} iconFirst />

            {(me?.Id === employeeId || employee?.NextOfKin) && (
                <>
                    <Divider />
                    <Box display="flex" flexDirection="column">
                        <Typography fontSize={'12px'} fontWeight={600} mb={'4px'}>
                            {p('next_of_kin')}
                        </Typography>
                        <LinkWithIcon
                            href={`tel:${employee?.NextOfKin?.Phone}`}
                            linkText={employee?.NextOfKin?.Name ? employee.NextOfKin.Name : p('missing_next_of_kin')}
                            customIcon={faPhone}
                            iconFirst
                            bgColor="warning"
                            disabled={!employee?.NextOfKin?.Phone}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};
