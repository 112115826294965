import {
    Children,
    forwardRef,
    Fragment,
    isValidElement,
    SyntheticEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import { faSearch, faX } from '@fortawesome/pro-regular-svg-icons';
import {
    Autocomplete,
    Box,
    ButtonBase,
    CircularProgress,
    darken,
    lighten,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import TbButton from '$components/muiBien/TbButton';
import { Ansatt } from '$helpers/types';
import { useEmployeeList } from '$hooks/useEmployeeList';
import { AvatarOrInitials } from './AvatarOrInitials';
import { almostBlack } from './TopBar';

import { drawerStateAtom, employeeIdAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const VirtualizedListboxComponent = forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>((props, ref) => {
    const { children, ...other } = props;
    const itemData = Children.toArray(children);

    const countCategories = itemData.length;

    const countItems = itemData.reduce((total: number, category) => {
        if (isValidElement(category)) {
            const groupLi: [] = category.props.children?.[1];
            if (Array.isArray(groupLi)) {
                return total + groupLi.length;
            }
        }
        return total;
    }, 0);

    const totalHeight = `${countCategories * 30 + countItems * 44}px`;

    return (
        <div ref={ref} {...other}>
            <Virtuoso
                style={{
                    margin: 0,
                    height: `calc(min(${totalHeight}, 600px))`,
                    overflowX: 'hidden',
                    scrollbarWidth: 'thin',
                    scrollbarGutter: '7px',
                    scrollbarColor: `${lighten(almostBlack, 0.5)} ${almostBlack}`,
                }}
                data={itemData}
                itemContent={(index, item) => <div key={index}>{item}</div>}
                overscan={{ main: 150, reverse: 0 }}
                firstItemIndex={0}
                totalCount={countCategories + countItems}
            />
        </div>
    );
});
VirtualizedListboxComponent.displayName = 'VirtualizedListboxComponent';

export const EmployeeSelectorVirtualized = () => {
    const theme = useTheme();
    const { employeeId, employee, me } = useAtomValue(drawerStateAtom);
    const setEmployeeId = useSetAtom(employeeIdAtom);
    const [isSearching, setIsSearching] = useState(false);
    const { employeeList, isLoading } = useEmployeeList({ enabled: isSearching });
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedValue, setSelectedValue] = useState<Ansatt | null>(employee);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSearchButton = () => {
        setIsSearching(true);
    };

    const handleClearSearch = () => {
        setSearchTerm('');
    };

    const handleFocus = () => {
        setIsSearching(true);
        setSearchTerm('');
    };

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.relatedTarget && event.relatedTarget.closest('.MuiAutocomplete-popper')) {
            return;
        }
        setIsSearching(false);
    };

    const handleChange = (_event: SyntheticEvent<Element, Event>, newValue: Ansatt | null) => {
        if (!newValue) return;
        setSelectedValue(newValue);
        setEmployeeId(newValue.Id);
        setIsSearching(false);
    };

    useEffect(() => {
        if (!isSearching) {
            setSelectedValue(employeeList.find((e) => e.Id === employeeId) ?? selectedValue);
        }
    }, [employeeId, employeeList, isSearching, selectedValue]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.ctrlKey && event.key === 'k') {
                event.preventDefault();
                inputRef.current?.focus();
            } else if (event.key === 'Escape') {
                event.preventDefault();
                setIsSearching(false);
                inputRef.current?.blur();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, []);

    const memoizedEmployeeList = useMemo(
        () => employeeList.sort((a, b) => a.AvdelingId.localeCompare(b.AvdelingId)),
        [employeeList]
    );

    return (
        <Autocomplete
            size="small"
            openOnFocus
            autoFocus
            autoHighlight
            blurOnSelect
            fullWidth
            componentsProps={{
                paper: {
                    sx: {
                        borderRadius: '0px 0px 6px 6px',
                        backgroundColor: theme.palette.background.paper,
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                        borderBottom: `2px solid ${almostBlack}`,
                        borderLeft: `1px solid ${almostBlack}`,
                        borderRight: `1px solid ${almostBlack}`,
                    },
                },
            }}
            loading={isLoading}
            value={selectedValue}
            onChange={handleChange}
            inputValue={searchTerm}
            isOptionEqualToValue={(option, value) => option.Id === value.Id}
            onInputChange={(_event, newInputValue) => setSearchTerm(newInputValue)}
            onBlur={() => setIsSearching(false)}
            onClose={(_event, reason) => {
                if (reason === 'toggleInput') return;
                inputRef.current?.blur();
            }}
            sx={{
                marginLeft: '8px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            }}
            color="#fff"
            ListboxComponent={VirtualizedListboxComponent}
            ListboxProps={{
                style: {
                    maxHeight: '800px',
                    overflow: 'hidden',
                    paddingTop: '0',
                    paddingBottom: '0',
                },
            }}
            groupBy={(option) => `${option.AvdelingId} ${option.AvdelingNavn}`}
            options={memoizedEmployeeList}
            renderGroup={(params) => (
                <Fragment key={params.key}>
                    <div
                        style={{
                            height: '30px',
                            position: 'sticky',
                            top: 0,
                            zIndex: 2,
                            paddingLeft: '8px',
                            paddingTop: '8px',
                            paddingBottom: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            backgroundColor: almostBlack,
                            color: '#fff',
                        }}
                    >
                        <Typography fontSize={'14px'} fontWeight={'600'}>
                            {params.group}
                        </Typography>
                    </div>
                    {params.children}
                </Fragment>
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.Id} style={{ padding: 0 }}>
                    <ButtonBase
                        onClick={() => {
                            setSelectedValue(option);
                            setEmployeeId(option.Id);
                            setIsSearching(false);
                        }}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            backgroundColor: option.Id === me?.Id ? theme.palette.primary.light : 'inherit',
                            textAlign: 'start',
                            padding: '8px 16px',
                            borderRadius: '4px',
                        }}
                    >
                        <AvatarOrInitials
                            firstName={option.Fornavn}
                            lastName={option.Etternavn}
                            innstemplet={option.Innstemplet}
                            imageUrl={option.Bilde}
                            diameter={28}
                            ml={'-8px'}
                        />
                        <Box width={'100%'} ml={'8px'} display={'flex'} flexDirection={'row'}>
                            <Typography noWrap mr={'10px'} fontSize={'14px'} fontWeight={600}>
                                {option.Id}
                            </Typography>
                            <Typography maxWidth={'80%'} noWrap fontSize={'14px'} fontWeight={400}>
                                {`${option.Fornavn} ${option.Etternavn}`}
                            </Typography>
                        </Box>
                    </ButtonBase>
                </li>
            )}
            getOptionLabel={(option) => `${option.Id} ${option.Fornavn} ${option.Etternavn}`}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputRef={inputRef}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            color: '#fff',
                            height: '40px',
                            paddingRight: '0',
                            paddingLeft: '0',
                            borderRadius: 0,
                            backgroundColor: isSearching
                                ? theme.palette.mode === 'light'
                                    ? lighten(almostBlack, 0.1)
                                    : darken(almostBlack, 0.75)
                                : almostBlack,
                        },
                        endAdornment: (
                            <>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {isSearching ? (
                                    <TbButton
                                        icon={faX}
                                        size="small"
                                        sx={{ width: '20px', height: '20px', color: 'white' }}
                                        onClick={handleClearSearch}
                                    />
                                ) : (
                                    <TbButton icon={faSearch} sx={{ color: 'white' }} onClick={handleSearchButton} />
                                )}
                            </>
                        ),
                    }}
                    InputLabelProps={{
                        sx: { color: '#fff' },
                    }}
                />
            )}
        />
    );
};
