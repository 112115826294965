import { Box } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import { baseUrl } from '$helpers/helpers';
import CalendarButton from './CalendarButton';
import { LinkWithIcon } from './LinkWithIcon';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const getUrls = (employeeId: number | null) => ({
    profile: `${baseUrl}/hjem/profil`,
    timeListe: `${baseUrl}/timeliste.asp?vis=a&ans=${employeeId}`,
    editEmployee: `${baseUrl}/kartotek_ansatte.asp?id=${employeeId}`,
    changeDepartment: `${baseUrl}/kartotek_ansatte.asp?id=${employeeId}#stilling`,
    turnus: `${baseUrl}/kartotek_ansatte.asp?id=${employeeId}#turnuser`,
    ferie: `${baseUrl}/kartotek_ansatte.asp?id=${employeeId}#dager`,
    plan: `${baseUrl}/plan`,
});

export const InfoLinks = () => {
    const { employeeId, me, hasAdminOrAvdLederAccess } = useAtomValue(drawerStateAtom);
    const p = usePhrases('meny');
    const urls = getUrls(employeeId);

    const isMe = me?.Id === employeeId;
    const isMeOrLederForAns = isMe || hasAdminOrAvdLederAccess;

    return (
        <Box display="flex" flexDirection="column" margin={1} gap={1}>
            {isMe && <LinkWithIcon href={urls.profile} linkText={p('my_profile')} noIcon />}
            {hasAdminOrAvdLederAccess && <LinkWithIcon href={urls.editEmployee} linkText={p('edit_employee')} noIcon />}
            {isMeOrLederForAns && <LinkWithIcon href={urls.timeListe} linkText={p('db_timeliste')} noIcon />}
            {hasAdminOrAvdLederAccess && (
                <LinkWithIcon href={urls.changeDepartment} linkText={p('change_department')} noIcon />
            )}
            {hasAdminOrAvdLederAccess && <LinkWithIcon href={urls.turnus} linkText={p('turnus')} noIcon />}
            {hasAdminOrAvdLederAccess && <LinkWithIcon href={urls.ferie} linkText={p('see_vac_days')} noIcon />}
            {isMe && me && <CalendarButton />}
        </Box>
    );
};
