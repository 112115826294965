import { Box } from '@mui/material';
import { SxProps } from '@mui/system';

type Props = {
    color?: string;
    size?: number | string;
    borderColor?: string;
    sx?: SxProps;
};

const PulsatingCircle = ({ color, size = 1, borderColor, sx }: Props) => {
    return (
        <Box
            width={size}
            height={size}
            borderRadius="50%"
            color={color}
            bgcolor={color}
            sx={{
                position: 'relative',
                boxShadow: `0 0 0 2px ${borderColor}`,
                '&::after': {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    animation: 'ripple 2.5s infinite ease-in-out',
                    border: `1px solid ${borderColor || 'currentColor'}`,
                    content: '""',
                },
                '@keyframes ripple': {
                    '0%': {
                        transform: 'scale(.8)',
                        opacity: 1,
                    },
                    '100%': {
                        transform: 'scale(2.4)',
                        opacity: 0,
                    },
                },
                ...sx,
            }}
        />
    );
};

export default PulsatingCircle;
