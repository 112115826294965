import { faCircle, faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Skeleton, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import PulsatingCircle from '$components/muiBien/PulsatingCircle';
import useClockedInHourLine from '$hooks/useClockedInHourLine';
import useSumHours from '$hooks/useSumHours';
import HoursBudgetBar from './oldcomponents/HoursBudgetBar';
import TodaysShifts from './oldcomponents/TodaysShifts';
import TreeBranch from './oldcomponents/TreeBranch';

import { drawerStateAtom, TabsEnum } from '~src/contextsAndAtoms/DrawerAtoms';

export const StatusTab = () => {
    const theme = useTheme();
    const { employeeId, employee, openTab, me } = useAtomValue(drawerStateAtom);
    const { clockedInHourLine, isLoadingClockedInHourLine } = useClockedInHourLine({
        employeeId: employeeId ?? 0,
        enabled: !!employeeId && openTab === TabsEnum.Status,
    });

    const p = usePhrases('meny');

    let showHoursBudgetOnProject = false;
    let showHoursBudgetOnJob = false;
    let showHoursBudgetOnActivity = false;

    if (clockedInHourLine) {
        if (clockedInHourLine.AktivitetPostId) {
            showHoursBudgetOnActivity = true;
        } else if (clockedInHourLine.ProsjektLinjePostId) {
            showHoursBudgetOnJob = true;
        } else if (clockedInHourLine.ProsjektPostId) {
            showHoursBudgetOnProject = true;
        }
    }

    const { sumHours: sumHoursProject } = useSumHours({
        enabled: showHoursBudgetOnProject,
        item: 'prosjekt',
        postId: clockedInHourLine?.ProsjektPostId ?? 0,
    });

    const { sumHours: sumHoursJob } = useSumHours({
        enabled: showHoursBudgetOnJob,
        item: 'prosjektlinje',
        postId: clockedInHourLine?.ProsjektLinjePostId ?? 0,
    });

    const { sumHours: sumHoursActivity } = useSumHours({
        enabled: showHoursBudgetOnActivity,
        item: 'aktivitet',
        postId: clockedInHourLine?.AktivitetPostId ?? 0,
    });

    let clockedInSince = '';
    if (clockedInHourLine) {
        const clockedInDate = new Date(clockedInHourLine.FraKlokken);
        // check if clockedInDate is today
        if (clockedInDate.getDate() !== new Date().getDate()) {
            clockedInSince = format(clockedInDate, 'dd.MM.yyyy HH:mm');
        } else {
            clockedInSince = format(clockedInDate, 'HH:mm');
        }
    }

    return (
        <Box display="flex" flexDirection="column" margin={'15px'}>
            <div>
                <Typography variant="h6" fontWeight={600}>
                    {p('status')}
                </Typography>
                <Divider sx={{ mt: '5px', mb: '10px' }} />
                {isLoadingClockedInHourLine ? (
                    <>
                        <Skeleton variant="rectangular" width="100%" height={24} />
                        <Divider sx={{ mt: '5px', mb: '10px' }} />
                    </>
                ) : !clockedInHourLine ? (
                    <>
                        <Box display="flex" alignItems="center" gap={1}>
                            <FontAwesomeIcon icon={faCircle} size="lg" color={theme.palette.grey[600]} />
                            {p('not_clocked_in')}
                        </Box>
                        <Divider sx={{ mt: '5px', width: '100%' }} />
                    </>
                ) : (
                    <>
                        <Box display="flex" flexDirection="column" gap={2}>
                            {clockedInHourLine.ArbeidsTypeNavn && clockedInHourLine.AvdelingNavn && (
                                <Typography>
                                    <FontAwesomeIcon icon={faClock} color={theme.palette.primary.main} />{' '}
                                    {clockedInHourLine.ArbeidsTypeNavn}
                                    {', '}
                                    {clockedInHourLine.AvdelingNavn}
                                </Typography>
                            )}
                            {clockedInHourLine.ProsjektNavn && (
                                <TreeBranch
                                    title={
                                        <div>
                                            <div>
                                                {clockedInHourLine.ProsjektId} {clockedInHourLine.ProsjektNavn}
                                            </div>
                                            {showHoursBudgetOnProject && (
                                                <HoursBudgetBar
                                                    hoursUsed={sumHoursProject.sumAntallTimer}
                                                    hoursBudget={sumHoursProject.timebudsjett}
                                                />
                                            )}
                                        </div>
                                    }
                                >
                                    {clockedInHourLine.ProsjektLinjeId && (
                                        <TreeBranch
                                            title={
                                                <div>
                                                    <div>
                                                        {clockedInHourLine.ProsjektLinjeId}{' '}
                                                        {clockedInHourLine.ProsjektLinjeNavn}
                                                    </div>
                                                    {showHoursBudgetOnJob && (
                                                        <HoursBudgetBar
                                                            hoursUsed={sumHoursJob.sumAntallTimer}
                                                            hoursBudget={sumHoursJob.timebudsjett}
                                                        />
                                                    )}
                                                </div>
                                            }
                                        >
                                            {clockedInHourLine.AktivitetId && (
                                                <TreeBranch
                                                    title={
                                                        <div>
                                                            <div>
                                                                {clockedInHourLine.AktivitetId}{' '}
                                                                {clockedInHourLine.AktivitetNavn}
                                                            </div>
                                                            {showHoursBudgetOnActivity && (
                                                                <HoursBudgetBar
                                                                    hoursUsed={sumHoursActivity.sumAntallTimer}
                                                                    hoursBudget={sumHoursActivity.timebudsjett}
                                                                />
                                                            )}
                                                        </div>
                                                    }
                                                />
                                            )}
                                        </TreeBranch>
                                    )}
                                </TreeBranch>
                            )}
                            <Box display="flex" alignItems="center" gap={1}>
                                <PulsatingCircle size="0.8rem" color={theme.palette.success.main} />
                                {me?.ErAdmin || (employee?.AvdelingId && me?.LederFor.includes(employee?.AvdelingId))
                                    ? p('top_drop_stamp') + ' ' + clockedInSince
                                    : p('clocked_in')}
                            </Box>
                        </Box>
                    </>
                )}
            </div>
            <Typography className="todays-shifts-label" fontWeight={600} mt={2}>
                {p('planned_shifts_today')}
            </Typography>
            <div className="todays-shifts">
                <Box display="flex" flexDirection="column" gap={1}>
                    <TodaysShifts employeeId={employeeId ?? 0} enabled={!!employeeId} />
                </Box>
            </div>
        </Box>
    );
};
