import { useState } from 'react';
import { faChevronDown, faChevronUp, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    darken,
    IconButton,
    lighten,
    SxProps,
    Typography,
    useTheme,
} from '@mui/material';

type SummaryType = {
    title: string;
    icon: IconDefinition;
    iconColor?: string;
    iconMarginLeft?: string;
    iconMarginRight?: string;
};
type CustomAccordionProps = {
    summary: SummaryType;
    details: JSX.Element;
    onChange?: (open: boolean) => Promise<void>;
    sx?: SxProps;
    disabled?: boolean;
    defaultExpanded?: boolean;
};

export const CustomAccordion = ({
    summary,
    details,
    sx,
    onChange,
    disabled = false,
    defaultExpanded = false,
}: CustomAccordionProps) => {
    const [expanded, setExpanded] = useState(defaultExpanded);
    const theme = useTheme();

    const handleChange = async () => {
        if (disabled) return;
        setExpanded(!expanded);
        if (onChange) await onChange(!expanded);
    };

    return (
        <Accordion
            sx={{
                ...sx,
                width: '100%',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor:
                    theme.palette.mode === 'light'
                        ? darken(theme.palette.background.default, 0.05)
                        : lighten(theme.palette.background.default, 0.05),
                color: disabled ? theme.palette.grey[500] : 'inherit',
                '&::before': { display: 'none' },
                '&.MuiAccordion-root.Mui-disabled': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.grey[500],
                },
            }}
            disableGutters
            expanded={!disabled && expanded}
            onChange={handleChange}
            slotProps={{ transition: { unmountOnExit: true } }}
            disabled={disabled}
        >
            <AccordionSummary
                sx={{
                    height: '10px',
                    marginY: '-8px',
                    color: disabled
                        ? theme.palette.grey[500]
                        : theme.palette.mode === 'light'
                        ? theme.palette.grey[600]
                        : theme.palette.grey[400],
                }}
            >
                <Box
                    display="flex"
                    justifyContent="start"
                    width="100%"
                    height="100%"
                    flexDirection="row"
                    alignItems="center"
                >
                    <FontAwesomeIcon
                        style={{
                            width: '15px',
                            height: '15px',
                            marginLeft: summary.iconMarginLeft,
                            marginRight: summary.iconMarginRight,
                        }}
                        size="2x"
                        icon={summary.icon}
                        color={disabled ? theme.palette.grey[500] : summary.iconColor ?? theme.palette.primary.main}
                    />
                    <Box
                        ml={'14px'}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="start"
                        width="100%"
                        height={'100%'}
                        color={theme.palette.primary.contrastText}
                        sx={{
                            opacity: 1,
                        }}
                    >
                        <Typography color={'inherit'} fontSize={'12px'}>
                            {summary.title}
                        </Typography>
                    </Box>
                    {!disabled && (
                        <IconButton
                            onClick={() => setExpanded(!expanded)}
                            size={'small'}
                            style={{ marginRight: '12px', width: '24px', height: '24px', color: 'inherit' }}
                        >
                            <FontAwesomeIcon size="xs" icon={expanded ? faChevronUp : faChevronDown} />
                        </IconButton>
                    )}
                </Box>
            </AccordionSummary>
            {!disabled && (
                <AccordionDetails
                    sx={{
                        paddingTop: '5px',
                        paddingBottom: '5px',
                        paddingX: '5px',
                        color: theme.palette.primary.contrastText,
                        borderTop: `1px solid ${theme.palette.divider}`,
                    }}
                >
                    {details}
                </AccordionDetails>
            )}
        </Accordion>
    );
};
