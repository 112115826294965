import { useState } from 'react';
import { faCheck, faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Box,
    Button,
    darken,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    lighten,
    Link,
    Skeleton,
    Typography,
    useTheme,
} from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import TbButton from '$components/muiBien/TbButton';
import { checkCalendarExists, deleteCalendar, fetchCalendarLink, initializeCalendar } from '$helpers/api';

const CalendarButton = () => {
    const [loading, setLoading] = useState(false);
    const [blobLink, setBlobLink] = useState<string | null>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [linkCopied, setLinkCopied] = useState(false);
    const [calendarExists, setCalendarExists] = useState(false);
    const theme = useTheme();
    const p = usePhrases('meny');

    const handleButtonClick = async () => {
        setLoading(true);

        try {
            setOpenDialog(true);
            const checkCalendar: boolean = await checkCalendarExists();
            setCalendarExists(checkCalendar);
            let link;
            if (checkCalendar) {
                link = await fetchCalendarLink();
                setBlobLink(link);
            }
        } catch (error) {
            console.error('Error fetching or initializing calendar:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSubscribeToCalendar = async () => {
        setLoading(true);
        try {
            const link = await initializeCalendar();
            setBlobLink(link);
            setCalendarExists(true);
        } catch (error) {
            console.error('Error initializing calendar:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteCalendar = async () => {
        try {
            await deleteCalendar();
            setOpenDialog(false);
            setBlobLink(null);
        } catch (error) {
            console.error('Error deleting calendar:', error);
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setLinkCopied(false);
    };
    const handleCopyLink = async () => {
        if (blobLink) {
            try {
                await navigator.clipboard.writeText(blobLink);
                setLinkCopied(true);
            } catch (error) {
                console.error('Failed to copy the link:', error);
            }
        }
    };

    const handleDeleteClick = () => {
        setDeleteConfirmOpen(true);
    };

    const handleConfirmDelete = async () => {
        setDeleteConfirmOpen(false);
        await handleDeleteCalendar();
    };

    const handleCancelDelete = () => {
        setDeleteConfirmOpen(false);
    };

    return (
        <div>
            <Box
                onClick={handleButtonClick}
                sx={{
                    width: '100%',
                    color: theme.palette.primary.main,
                    height: '28px',
                    padding: '5px',
                    fontSize: '14px',
                    fontWeight: 800,
                    backgroundColor: lighten(theme.palette.primary.main, 0.9),
                    cursor: 'pointer',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                        bgcolor:
                            theme.palette.mode === 'light'
                                ? lighten(theme.palette.primary.main, 0.8)
                                : darken(theme.palette.primary.dark, 0.4),
                        color: theme.palette.primary.main,
                    },
                }}
            >
                <Typography fontSize={'12px'} fontWeight={700} sx={{ textDecoration: 'underline' }}>
                    {p('subscribe_to_calendar')}
                </Typography>
            </Box>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>{p('calendar_link')}</DialogTitle>
                <DialogContent>
                    {loading ? (
                        <Box sx={{ width: '600px', maxWidth: '90%' }}>
                            <Skeleton variant="rectangular" width="100%" height={70} sx={{ my: 2 }} />
                            <Skeleton variant="text" width="90%" height={20} />
                            <Skeleton variant="text" width="50%" height={20} />
                            <Skeleton variant="text" width="70%" height={20} />
                        </Box>
                    ) : calendarExists ? (
                        <>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    backgroundColor: theme.palette.grey[200],
                                    padding: '8px',
                                    borderRadius: '6px',
                                    cursor: 'pointer',
                                    marginBottom: '16px',
                                }}
                                onClick={handleCopyLink}
                            >
                                <Typography variant="body1" sx={{ wordBreak: 'break-all', flexGrow: 1 }}>
                                    {blobLink}
                                </Typography>
                                <FontAwesomeIcon
                                    icon={linkCopied ? faCheck : faCopy}
                                    size="2x"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleCopyLink();
                                    }}
                                    style={{
                                        marginLeft: '8px',
                                        cursor: 'pointer',
                                        transform: linkCopied ? 'rotate(360deg)' : 'rotate(0deg)',
                                        transition: ' all 0.4s ease-in-out',
                                        color: linkCopied ? theme.palette.success.main : theme.palette.text.primary,
                                    }}
                                />
                            </Box>

                            <Typography variant="body1" gutterBottom>
                                {p('your_calendar_link')} {p('copy_link_and_add_to_your_calendar')}
                            </Typography>

                            <Link href="https://tidsbanken.net/support/case.asp?ID=860" target="_blank" rel="noopener">
                                {p('click_here_for_info')}
                            </Link>

                            <Typography variant="body2" color="textSecondary" sx={{ marginTop: '16px' }}>
                                {p('do_you_wish_to_unsubscribe')}{' '}
                                <Link href="#" onClick={handleDeleteClick}>
                                    {p('click_here')}
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'start'}
                            gap={2}
                            marginBottom={'-15px'}
                        >
                            <Typography maxWidth={'600px'}>{p('subscribe_text')}</Typography>
                            <TbButton onClick={handleSubscribeToCalendar} variant="outlined" color="success" fullWidth>
                                {p('subscribe_to_calendar')}
                            </TbButton>
                            <Link href="https://tidsbanken.net/support/case.asp?ID=860" target="_blank" rel="noopener">
                                {p('click_here_for_info')}
                            </Link>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        {p('close')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={deleteConfirmOpen} onClose={handleCancelDelete}>
                <DialogTitle>{p('confirm_unsubscribe')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{p('confirm_unsubscribe_text')}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        {p('cancel')}
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary" variant="contained">
                        {p('unsubscribe')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CalendarButton;
