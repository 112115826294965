import { Box } from '@mui/material';
import { Ansattkalender } from '@tidsbanken/ansattkalender';
import { useAtomValue } from 'jotai';
import useIsMobile from '$hooks/useIsMobile';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const PlanTab = () => {
    const { employeeId, hasAdminOrAvdLederAccess, me } = useAtomValue(drawerStateAtom);

    const isMobile = useIsMobile();

    return (
        <Box marginLeft={'-10px'}>
            <Ansattkalender
                ansattId={employeeId ?? me?.Id}
                width={isMobile ? window.innerWidth : 400}
                sx={{
                    marginTop: hasAdminOrAvdLederAccess || me?.Id === employeeId ? '-10px' : '5px',
                    boxShadow: 'none',
                }}
            />
        </Box>
    );
};
