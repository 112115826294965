import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Theme, ThemeProvider } from '@mui/material';
import { useLocalStorage } from '$hooks/useLocalStorage';
import { muiDark, muiLight } from './muiTheme';

export type ThemeContextProps = { theme: Theme; setTheme: (theme: Theme) => void };

const ThemeContext = createContext<ThemeContextProps>({} as ThemeContextProps);
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

export const ThemeContextProvider = (props: { children: React.ReactNode }) => {
    /*
    const [persistedDarkMode, setPersistedDarkMode] = useLocalStorage<boolean | undefined>(
        'userPrefersDarkMode',
        false
    );
    const [theme, setTheme] = useState<Theme>(
        persistedDarkMode && location.hostname !== 'min.tidsbanken.net' ? muiDark : muiLight
    );
    useEffect(() => {
        if (theme === muiDark) {
            document.querySelector('html')?.classList.add('dark-mode');
        } else {
            document.querySelector('html')?.classList.remove('dark-mode');
        }
    }, [theme]);
    const handleChange = useCallback(
        (event: MediaQueryListEvent) => {
            const newColorScheme = event.matches ? 'dark' : 'light';
            if (newColorScheme === 'dark') {
                setTheme(muiDark);
                setPersistedDarkMode(true);
            } else {
                setTheme(muiLight);
                setPersistedDarkMode(false);
            }
        },
        [setTheme, setPersistedDarkMode]
    );
    useEffect(() => {
        darkModeMediaQuery.addEventListener('change', handleChange);
        return () => {
            darkModeMediaQuery.removeEventListener('change', handleChange);
        };
    }, [handleChange]);

    const value = useMemo(
        () => ({
            theme,
            setTheme: (theme: Theme) => {
                setTheme(theme);
                setPersistedDarkMode(theme === muiDark);
            },
        }),
        [setPersistedDarkMode, theme]
    );
    */

    const value = useMemo(
        () => ({
            theme: muiLight,
            setTheme: () => {
            },
        }),
        []
    );
    return (
        <ThemeContext.Provider value={value}>
            {' '}
            <ThemeProvider theme={muiLight}>{props.children}</ThemeProvider>{' '}
        </ThemeContext.Provider>
    );
};
export const useThemeContext = () => useContext(ThemeContext);
