import { getPhrases } from "./api";
import { clearPhrases } from "./clearPhrases";
var init_used = false;
var init_warned = false;
export function initUsageWarning() {
    if (!init_used && !init_warned) {
        console.warn("PHRASE_WARNING:", "The \"init\" function has not been called before first use of phrases. This may have adverse effects, and could be unsupported in the future. See docs for info on how to use the \"init\" function.");
        init_warned = true;
    }
}
export function init() {
    var domains = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        domains[_i] = arguments[_i];
    }
    init_used = true;
    // First, clear cache if query parameter "login" is present.
    var url = new URL(location.href);
    if (url.searchParams.has("login")) {
        // This should only happen once, so "login" is removed.
        url.searchParams.delete("login");
        // Replace history with try/catch for safety (IE11 will fail)
        try {
            history.replaceState(history.state, "", url.toString());
        }
        catch (e) {
            // Moving on...
        }
        // Remove all cached phrases
        clearPhrases();
    }
    // Preload phrase domains
    for (var _a = 0, domains_1 = domains; _a < domains_1.length; _a++) {
        var domain = domains_1[_a];
        getPhrases(domain);
    }
}
;
