import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getAnsattInfo } from '$helpers/api';

export const rootCacheKey = ['ansattInfo'];
export const useEmployeeInfo = (employeeId: number | null) => {
    const { data, isLoading, isFetching } = useQuery({
        queryKey: [...rootCacheKey, employeeId],
        queryFn: () => getAnsattInfo(employeeId ?? -1),
        enabled: !!employeeId,
        placeholderData: keepPreviousData,
    });

    const employeeInfo = data;

    return {
        employeeInfo,
        isLoadingAnsattInfo: isLoading || isFetching,
    };
};
