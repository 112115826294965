var LS_PREFIX = "phrases_";
var LocalStorageTTL = /** @class */ (function () {
    function LocalStorageTTL(ttl) {
        this.ttl = ttl;
    }
    LocalStorageTTL.prototype.get = function (key) {
        var itemStr = localStorage.getItem(key);
        if (itemStr === null) {
            return null;
        }
        var item = JSON.parse(itemStr);
        var now = Date.now();
        if (now > item.expires) {
            localStorage.removeItem(key);
            return null;
        }
        return item.value;
    };
    LocalStorageTTL.prototype.set = function (key, value) {
        var item = {
            value: value,
            expires: Date.now() + this.ttl * 1000
        };
        localStorage.setItem(key, JSON.stringify(item));
    };
    return LocalStorageTTL;
}());
export { LocalStorageTTL };
export default LocalStorageTTL;
