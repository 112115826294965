import { useQuery } from '@tanstack/react-query';
import { getSumHours } from '$helpers/api';

type Props = {
    enabled: boolean;
    item: 'prosjekt' | 'prosjektlinje' | 'aktivitet';
    postId?: number;
};

const rootCacheKey = ['sumTimer'];

const useSumHours = ({ enabled, item, postId = -1 }: Props) => {
    const cacheKey = [...rootCacheKey, { item, postId }];
    
    const { data, isLoading, isFetching } = useQuery({
        queryKey: cacheKey,
        queryFn: () => getSumHours([postId], item),
        enabled,
    });

    const sumHours = data?.[0] ?? {
        postId,
        sumAntallTimer: 0,
        timebudsjett: 0,
    };

    return {
        sumHours,
        isLoadingSumHours: isLoading || isFetching,
    };
};

export default useSumHours;
