import { useQueryClient } from '@tanstack/react-query';
import { AnsattPanelFerie, OnskeStatus } from '$helpers/types';
import { rootCacheKey } from '$hooks/useFerieInfo';

import { SignalRContext } from '~src/contextsAndAtoms/SignalREventHubContext';

export const useSignalREffects = (ferieInfo: AnsattPanelFerie[] | undefined, cacheKey: string[]) => {
    const queryClient = useQueryClient();

    SignalRContext.useSignalREffect(
        'FerieonskeOnskeStatusChanged',
        (...args) => {
            const approvalChangedEvent = args[0] as { ids: number[]; approved: OnskeStatus };

            const ferieEntries = ferieInfo?.flatMap((fi) => fi.FerieEntries);

            const changedEntries = ferieEntries?.filter((fe) => approvalChangedEvent.ids.includes(fe.Id));
            if (changedEntries?.length && changedEntries.length > 0) {
                queryClient.invalidateQueries({ queryKey: rootCacheKey });
            }
        },
        [ferieInfo, queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieonskerCreated',
        () => {
            queryClient.invalidateQueries({ queryKey: rootCacheKey });
        },
        [queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieonskerDeleted',
        (...args) => {
            const deletedIds = args[0] as number[];

            const ferieEntries = ferieInfo?.flatMap((fi) => fi.FerieEntries);

            const deletedEntries = ferieEntries?.filter((fe) => deletedIds.includes(fe.Id));
            if (deletedEntries?.length && deletedEntries.length > 0) {
                queryClient.invalidateQueries({ queryKey: rootCacheKey });
            }
        },
        [ferieInfo, queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieEndrePlanChanged',
        () => {
            queryClient.invalidateQueries({ queryKey: rootCacheKey });
        },
        [queryClient, cacheKey]
    );
};
