import { Card, Skeleton, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { decimalColorToHexColor, getContrastYIQ } from '$helpers/colorHelper';
import useTodaysShifts from '$hooks/useTodaysShifts';

type Props = {
    enabled: boolean;
    employeeId: number;
};

const TodaysShifts = ({ enabled, employeeId }: Props) => {
    const { todaysShifts, isLoadingTodaysShifts } = useTodaysShifts({
        employeeId,
        enabled,
    });
    const p = usePhrases('meny');

    return (
        <>
            {isLoadingTodaysShifts ? (
                <Skeleton variant="rectangular" width="100%" height={24} />
            ) : todaysShifts.length === 0 ? (
                <Typography>{p('no_shifts_emp_drawer')}</Typography>
            ) : (
                todaysShifts.map((shift) => {
                    const shiftColor = decimalColorToHexColor(shift?.ArbeidsTypeWebBg)
                    const textColor = getContrastYIQ(shiftColor);
                    return (
                        <Card key={shift.Id} sx={{ bgcolor: shiftColor, p: 1 }}>
                            <Typography color={textColor} variant="body1" fontWeight={600}>
                                {shift.ArbeidsTypeNavn ?? 'Ukjent arbeidstype'}
                            </Typography>
                            <Typography color={textColor} variant="body2">
                                {shift.AvdelingNavn ?? 'Ukjent avdeling'}
                            </Typography>
                            {shift.ProsessTypeNavn && (
                                <Typography color={textColor} variant="body2">
                                    {shift.ProsessTypeNavn}
                                </Typography>
                            )}
                            <Typography color={textColor} variant="body1">
                                {format(new Date(shift.FraKlokken ?? ''), 'HH:mm')} -{' '}
                                {format(new Date(shift.TilKlokken ?? ''), 'HH:mm')}
                            </Typography>
                        </Card>
                    );
                })
            )}
        </>
    );
};

export default TodaysShifts;
