import { Dispatch, SetStateAction } from 'react';
import { Box, Checkbox, Divider, FormControlLabel, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import TbButton from '$components/muiBien/TbButton';
import { FerieInfoText } from '../FerieInfoText';

export const FerieBehandlingInfo = ({
    handleSaveClick,
    setVisBehandlede,
    disabled,
    loading,
    sx,
}: {
    handleSaveClick: () => void;
    setVisBehandlede: Dispatch<SetStateAction<boolean>>;
    disabled: boolean;
    loading: boolean;
    sx?: SxProps<Theme>;
}) => {
    const p = usePhrases('meny');
    const theme = useTheme();
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width={'100%'}
            mb={'-20px'}
            sx={
                sx ?? {
                    position: 'absolute',
                    bottom: '82px',
                    paddingBottom: '10px',
                    left: '0',
                    right: '0',
                    padding: '10px',
                    borderTop: `2px solid ${theme.palette.divider}`,
                    backgroundColor: theme.palette.background.default,
                }
            }
        >
            <FormControlLabel
                sx={{
                    mb: '-10px',
                    mt: '-15px',
                }}
                control={<Checkbox onChange={(e) => setVisBehandlede(e.target.checked)} />}
                label={
                    <Typography sx={{ userSelect: 'none' }} fontSize={14}>
                        {p('show_processed_vacation_requests')}
                    </Typography>
                }
            />
            <Divider sx={{ width: '60%', margin: '5px 0' }} />
            <FerieInfoText />
            <TbButton
                disabled={disabled}
                loading={loading}
                fullWidth
                variant="contained"
                sx={{
                    height: '30px',
                    marginTop: '10px',
                    backgroundColor: theme.palette.primary.main,
                    color: '#fff',
                }}
                onClick={handleSaveClick}
            >
                {p('save')}
            </TbButton>
        </Box>
    );
};
