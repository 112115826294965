import { Box, Button, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { logOut } from '$helpers/api';

export const LogoutTab = () => {
    const p = usePhrases('meny');

    const handleLogOutClick = async () => {
        await logOut();
    };

    return (
        <Box
            width={'100%'}
            height={'100%'}
            display="flex"
            flexDirection="column"
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Typography mb={'10px'} fontSize={'14px'} fontWeight={400}>
                {p('confirm_logout')}
            </Typography>
            <Button onClick={handleLogOutClick} variant="outlined" color="error">
                {p('logout')}
            </Button>
        </Box>
    );
};
