import { faCalendarDay, faClockFour, faTreePalm } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';

export const FerieStatusHeader = () => {
    const p = usePhrases('meny');
    return (
        <>
            <Typography fontSize={14} fontWeight={600} gridArea={'dato'}>
                {p('date')}
            </Typography>
            <Tooltip title={p('requested')} placement="bottom">
                <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'onsk'}>
                    <FontAwesomeIcon icon={faTreePalm} size="1x" />
                </Box>
            </Tooltip>
            <Tooltip title={p('planned')} placement="bottom">
                <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'plan'}>
                    <FontAwesomeIcon icon={faCalendarDay} size="1x" />
                </Box>
            </Tooltip>
            <Tooltip title={p('done')} placement="bottom">
                <Box display="flex" justifyContent={'center'} alignItems={'center'} gridArea={'reg'}>
                    <FontAwesomeIcon icon={faClockFour} size="1x" />
                </Box>
            </Tooltip>
            <Divider sx={{ width: '100%', mt: '10px 0', gridColumn: '1 / -1' }} />
        </>
    );
};
