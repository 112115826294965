import { Avatar, Box, SxProps, useTheme } from '@mui/material';
import PulsatingCircle from '$components/muiBien/PulsatingCircle';

type AvatarProps = {
    imageUrl?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    innstemplet?: boolean;
    diameter?: number;
    onClick?: (e: never) => void;
    ml?: string | number;
    sx?: SxProps;
};

export const AvatarOrInitials = ({
    imageUrl,
    firstName,
    lastName,
    innstemplet,
    diameter = 36,
    onClick,
    ml,
    sx,
}: AvatarProps) => {
    const theme = useTheme();
    const initials = getInitials(firstName, lastName);
    const color = stringToColour(`${firstName} ${lastName}`);
    const size = `${diameter}px`;

    return (
        <Box position="relative" display="inline-block" sx={{ ...sx, marginLeft: ml ?? '10px' }} onClick={onClick}>
            <Avatar
                src={imageUrl || undefined}
                alt={`${firstName} ${lastName}`}
                sx={{
                    width: size,
                    height: size,
                    bgcolor: color,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: diameter < 30 ? 12 : 16,
                    fontWeight: diameter < 30 ? 400 : 600,
                }}
            >
                {!imageUrl && initials}
            </Avatar>

            {innstemplet && (
                <PulsatingCircle
                    size="12px"
                    color={theme.palette.success.main}
                    sx={{
                        position: 'absolute',
                        bottom: '-2px',
                        right: '-2px',
                        zIndex: 1,
                        border: `1px solid ${theme.palette.success.light}`,
                    }}
                />
            )}
        </Box>
    );
};

// Helper functions
const stringToColour = (str: string | null | undefined) => {
    if (!str) return '#f0f2f2';
    let hash = 0;
    str = str.trim().toUpperCase().replace(/\s/g, '');
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return `#${Array(3)
        .fill('')
        .map((_, i) => {
            const value = (hash >> (i * 8)) & 0xff;
            return ((value * 0.65 + 0.8 * 255 * 0.35) | 0).toString(16).padStart(2, '0');
        })
        .join('')}`;
};

const getInitials = (firstName: string | null | undefined, lastName?: string | null | undefined) => {
    return `${firstName?.charAt(0) ?? ''}${lastName?.charAt(0) ?? ''}`.toUpperCase();
};
