import { useQuery } from '@tanstack/react-query';
import { getTodaysShifts } from '$helpers/api';

type Props = {
    employeeId: number | null;
};

const rootCacheKey = ['todaysShifts'];

const useTodaysShifts = ({ employeeId }: Props) => {
    const cacheKey = [...rootCacheKey, { employeeId }];
    
    const { data, isLoading, isFetching } = useQuery({
        queryKey: cacheKey,
        queryFn: () => getTodaysShifts(employeeId),
        enabled: !!employeeId,
    });

    const todaysShifts = data ?? [];

    return {
        todaysShifts,
        isLoadingTodaysShifts: isLoading || isFetching,
    };
};

export default useTodaysShifts;
