import { LinearProgress, Tooltip } from "@mui/material";
import { formatNumber } from "$helpers/numberHelper";

type HoursBudgetBarProps = {
    hoursUsed: number;
    hoursBudget: number;
};

const HoursBudgetBar = ({ hoursUsed, hoursBudget }: HoursBudgetBarProps) => {
    let hoursUsedPercentage = 100;
    if (hoursBudget > 0) {
        hoursUsedPercentage = (hoursUsed / hoursBudget) * 100;
    } else if (hoursUsed <= 0) {
        hoursUsedPercentage = 0;
    }

    if (hoursUsedPercentage > 100) {
        hoursUsedPercentage = 100;
    }

    let color: 'primary' | 'secondary' | 'error' | 'inherit' | undefined = 'inherit';
    if (hoursUsedPercentage > 0 && hoursUsedPercentage < 80) {
        color = 'primary';
    } else if (hoursUsedPercentage >= 80 && hoursUsedPercentage <= 95) {
        color = 'secondary';
    } else if (hoursUsedPercentage > 95) {
        color = 'error';
    }

    return (
        <Tooltip title={`Brukt ${formatNumber(hoursUsed)} av ${formatNumber(hoursBudget)} timer`}>
            <LinearProgress
                color={color}
                variant="determinate"
                value={hoursUsedPercentage}
                className="project-line-progress"
                sx={{
                    borderRadius: 5,
                    height: '0.4rem',
                    '.MuiLinearProgress-bar': {
                        // MUI progress bar uses transform to display progress, which ruins dnd image preview
                        width: `${hoursUsedPercentage}%`,
                        transform: 'unset !important',
                    },
                }}
            />
        </Tooltip>
    );
};

export default HoursBudgetBar;
