import { Box, Typography, useTheme } from '@mui/material';
import { P, usePhrases } from '@tidsbanken/phrases';
import { differenceInMonths, differenceInYears, format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useEmployeeInfo } from '$hooks/useEmployeeInfo';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const formatYears = (startDate: Date, p: P) => {
    const years = differenceInYears(Date.now(), new Date(startDate));
    const yearLabel = years === 1 ? p('year') : p('years');
    return `${years} ${yearLabel}`;
};

const formatYearsAndMonths = (startDate: Date, p: P) => {
    const now = new Date();
    const years = differenceInYears(now, new Date(startDate));
    const months = differenceInMonths(now, new Date(startDate)) % 12;

    const yearLabel = years === 1 ? p('year') : p('years');
    const monthLabel = months === 1 ? p('month') : p('months');

    return `${years} ${yearLabel}, ${months} ${monthLabel}`;
};

export const EmployeeInfo = () => {
    const theme = useTheme();
    const p = usePhrases('meny');
    const { employeeId, employee } = useAtomValue(drawerStateAtom);
    const { employeeInfo } = useEmployeeInfo(employeeId ?? -1);

    const formattedAge = employeeInfo?.Birthdate
        ? `${format(new Date(employeeInfo.Birthdate), 'dd.MM.yyyy')} (${formatYears(
              new Date(employeeInfo.Birthdate),
              p
          )})`
        : p('missing_birthdate');

    const formattedEmployedDate = employeeInfo?.EmployedDate
        ? formatYearsAndMonths(new Date(employeeInfo.EmployedDate), p)
        : p('missing_employed_date');
    return (
        <Box display={'flex'} flexDirection={'column'} padding={1} gap={1}>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={'5px'}
                borderRadius={'5px'}
                height={'28px'}
                bgcolor={theme.palette.grey[100]}
            >
                <Typography fontSize={'12px'} fontWeight={700}>
                    {p('department')}:
                </Typography>
                <Typography fontSize={'12px'}>{employee?.AvdelingNavn}</Typography>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={'5px'}
                borderRadius={'5px'}
                height={'28px'}
                bgcolor={theme.palette.grey[100]}
            >
                <Typography fontSize={'12px'} fontWeight={700}>
                    {p('birthdate')}:
                </Typography>
                <Typography
                    fontSize={'12px'}
                    color={employeeInfo?.Birthdate ? theme.palette.primary.contrastText : theme.palette.grey[500]}
                >
                    {formattedAge}
                </Typography>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                p={'5px'}
                borderRadius={'5px'}
                height={'28px'}
                bgcolor={theme.palette.grey[100]}
            >
                <Typography fontSize={'12px'} fontWeight={700}>
                    {p('employed_for')}:
                </Typography>
                <Typography
                    fontSize={'12px'}
                    color={employeeInfo?.EmployedDate ? theme.palette.primary.contrastText : theme.palette.grey[500]}
                >
                    {formattedEmployedDate}
                </Typography>
            </Box>
        </Box>
    );
};
