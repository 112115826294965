import { useQuery } from '@tanstack/react-query';
import { getFerieInfo } from '$helpers/api';

type Props = {
    databaseId: string;
    employeeId: number;
    from: Date;
    to: Date;
};

export const rootCacheKey = ['ferieInfo'];

const useFerieInfo = ({ databaseId, employeeId, from, to }: Props) => {
    const cacheKey = [...rootCacheKey, { databaseId, employeeId, from, to }];

    const { data, isLoading, isFetching, isInitialLoading } = useQuery(
        cacheKey,
        async () => await getFerieInfo(employeeId, from, to),
        {
            enabled: true,
            keepPreviousData: true,
            refetchOnMount: 'always',
        }
    );

    const ferieInfo = data ?? [];

    return {
        ferieInfo,
        isLoading: isLoading || isFetching,
        isInitialLoading,
        cacheKey,
    };
};

export default useFerieInfo;
