/* eslint-disable @typescript-eslint/ban-ts-comment */
import { format } from 'date-fns';
import {
    Ansatt,
    AnsattPanelFerie,
    BehandleOnskePayload,
    Bemanningsplan,
    DeleteOnskePayload,
    Fridag,
    Me,
    NotificationType,
    SumPerEnhet,
    Timelinje,
    VacationNumbersResponse,
} from './types';

const minDomainURL = 'min.tidsbanken.net';

const getEnvironment = (domene: string) =>
    window.location.hostname !== minDomainURL
        ? `https://api-${domene}-sandkasse.tidsbanken.net`
        : `https://api-${domene}.tidsbanken.net`;

const getEnvironmentOld = (domene: string) =>
    window.location.hostname !== minDomainURL
        ? `https://sandkasse.tidsbanken.net/api/${domene}`
        : `https://min.tidsbanken.net/api/${domene}`;

const getEnvironmentV2 = (domene: string) =>
    window.location.hostname !== minDomainURL
        ? `https://api-${domene}-sandkasse.tidsbanken.net`
        : `https://api-${domene}-v2.tidsbanken.net`;

const apiEmployeesUrl = getEnvironment('ansatte');
const apiMeUrl = getEnvironment('me');
const authUrl =
    window.location.hostname === 'min.tidsbanken.net'
        ? 'https://auth.tidsbanken.net'
        : 'https://auth-sandkasse.tidsbanken.net';
const apiTimelineUrl = getEnvironmentOld('timelinje');
const apiProsjektUrl = getEnvironmentV2('prosjekt');
const apiPlanleggingUrl = getEnvironmentOld('planlegging');
export const apiEventsUrl = getEnvironment('events');
export const vacationNumbersURL = apiPlanleggingUrl + '/vacationnumber';
export const apiVarslingUrl = getEnvironment('varslinger');
export const onskeUrl = apiPlanleggingUrl + '/onsker';

const performRequest = async (url: string, method: string, body?: object) => {
    const response = await fetch(`${url}`, {
        method,
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    if (response.status < 200 || response.status >= 300) {
        try {
            throw await response.json();
        } catch (error) {
            throw new Error(`${response.status}`);
        }
    }
    if (response.status === 204) {
        return [];
    }
    return response.json();
};

const get = async (url: string) => {
    return performRequest(url, 'GET');
};

export const getEmployee = async (id: number): Promise<Ansatt> => {
    return get(`${apiEmployeesUrl}/AnsatteKontaktinfo(${id})`);
};

export const getMe = async (): Promise<Me> => {
    return get(apiMeUrl);
};

export const logOut = async () => {
    await fetch(`${authUrl}/api/SignOut/Bruker`, {
        mode: 'no-cors',
        credentials: 'include',
    });

    window.location.href = authUrl;
};

export const getClockedInHourLines = async (employeeId: number): Promise<Timelinje[]> => {
    return get(`${apiTimelineUrl}/TimelinjeInnstemplet/ForAnsattpanel/${employeeId}`);
};

export const getSumHours = (
    postIds: number[],
    item: 'prosjekt' | 'prosjektlinje' | 'aktivitet'
): Promise<SumPerEnhet[]> => {
    return get(`${apiProsjektUrl}/${item}/SumTimer?ids=${postIds.join(',')}`);
};

export const getTodaysShifts = async (employeeId: number): Promise<Bemanningsplan[]> => {
    const startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
    const endOfToday = new Date(startOfToday);
    endOfToday.setDate(endOfToday.getDate() + 1);
    endOfToday.setMilliseconds(-1);

    return get(
        `${apiPlanleggingUrl}/vakterforansattpanel?AnsattId=${employeeId}&FromInclusive=${startOfToday.toISOString()}&ToInclusive=${endOfToday.toISOString()}`
    );
};

export const getFerieInfo = async (employeeId: number, from: Date, to: Date): Promise<AnsattPanelFerie[]> => {
    return singleItemFetch(
        `${apiPlanleggingUrl}/ansattPanel/ferie/${employeeId}?from=${format(from, 'yyyy-MM-dd')}&to=${format(
            to,
            'yyyy-MM-dd'
        )}`
    );
};

export const getHolidays = (from: Date, to: Date): Promise<Fridag[]> => {
    return singleItemFetch(
        `${apiPlanleggingUrl}/systemKalender/fridager?from=${from.toISOString()}&to=${to.toISOString()}`
    );
};

export const getRemainingVacationDays = async (employeeId: number, date: string): Promise<VacationNumbersResponse> => {
    return singleItemFetch(`${vacationNumbersURL}/${employeeId}?date=${date}`);
};

export const singleItemPatch = (url: string, payload?: string) => {
    return fetch(`${url}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: payload,
        keepalive: true,
    });
};

// GET
export const singleItemFetch = async (url: string) => {
    const response = await fetch(`${url}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.status === 401 || response.status === 403) {
        throw new Error('Unauthorized');
    }
    if (response.status === 204) {
        return [];
    }
    return response.json();
};

const singleItemDelete = async (url: string, payload?: string) => {
    const response = await fetch(`${url}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: payload,
    });
    if (response.status === 401 || response.status === 403) {
        throw new Error('Unauthorized');
    }
    if (response.status === 500) {
        throw new Error(response.statusText);
    }
    return response;
};

export const singleItemPost = async (url: string, payload: string) => {
    return fetch(`${url}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: payload,
        keepalive: true,
    });
};

export const behandleOnskeRequest = (payload: BehandleOnskePayload) => {
    return singleItemPatch(`${onskeUrl}/SetOnskeStatus`, JSON.stringify(payload));
};

export const deleteOnskeRequest = (onskeIds: DeleteOnskePayload) => {
    return singleItemDelete(onskeUrl, JSON.stringify(onskeIds));
};

export const postNotification = async (payload: NotificationType) => {
    return singleItemPost(`${apiVarslingUrl}/Varslinger`, JSON.stringify(payload));
};
