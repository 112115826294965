import { createRoot } from 'react-dom/client';
import { init } from '@tidsbanken/phrases';
import { EmployeeDrawerConfig } from '$helpers/types';
import App from './App';

declare global {
    interface Window {
        employeePanel: {
            employeeId: number | null;
            open: (employeeId: number, config?: EmployeeDrawerConfig) => void;
            close: () => void;
            isOpen: boolean;
        };
    }
}

try {
    init('meny');

    const container = document.getElementById('ansatt-panel') as HTMLDivElement;
    const root = createRoot(container);
    root.render(<App />);
} catch (error) {
    console.warn(
        'Ansattpanel crashed: ' +
            'Caught error in init.tsx. ' +
            'Dependencies seem to work, but maybe an application ' +
            'error popped all the way up here?',
        error
    );
}
