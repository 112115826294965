import { faCircle, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Skeleton, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import useClockedInHourLine from '$hooks/useClockedInHourLine';
import useSumHours from '$hooks/useSumHours';
import HoursBudgetBar from './HoursBudgetBar';
import TreeBranch from './TreeBranch';

import { drawerStateAtom, TabsEnum } from '~src/contextsAndAtoms/DrawerAtoms';

export const ClockedIn = () => {
    const { employeeId, openTab } = useAtomValue(drawerStateAtom);
    const theme = useTheme();
    const { clockedInHourLine, isLoadingClockedInHourLine } = useClockedInHourLine({
        employeeId: employeeId ?? 0,
        enabled: !!employeeId && openTab === TabsEnum.Status,
    });

    const p = usePhrases('meny');

    let showHoursBudgetOnProject = false;
    let showHoursBudgetOnJob = false;
    let showHoursBudgetOnActivity = false;

    if (clockedInHourLine) {
        if (clockedInHourLine.AktivitetPostId) {
            showHoursBudgetOnActivity = true;
        } else if (clockedInHourLine.ProsjektLinjePostId) {
            showHoursBudgetOnJob = true;
        } else if (clockedInHourLine.ProsjektPostId) {
            showHoursBudgetOnProject = true;
        }
    }

    const { sumHours: sumHoursProject } = useSumHours({
        enabled: showHoursBudgetOnProject,
        item: 'prosjekt',
        postId: clockedInHourLine?.ProsjektPostId ?? 0,
    });

    const { sumHours: sumHoursJob } = useSumHours({
        enabled: showHoursBudgetOnJob,
        item: 'prosjektlinje',
        postId: clockedInHourLine?.ProsjektLinjePostId ?? 0,
    });

    const { sumHours: sumHoursActivity } = useSumHours({
        enabled: showHoursBudgetOnActivity,
        item: 'aktivitet',
        postId: clockedInHourLine?.AktivitetPostId ?? 0,
    });
    return isLoadingClockedInHourLine ? (
        <>
            <Skeleton variant="rectangular" width="100%" height={24} />
        </>
    ) : !clockedInHourLine ? (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <FontAwesomeIcon icon={faCircle} color={theme.palette.grey[600]} />
                <Typography fontSize={'12px'}>{p('not_clocked_in')}</Typography>
            </Box>
        </>
    ) : (
        <Paper
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                padding: '10px 10px 15px 10px',
                '& .MuiBox-root': {
                    width: '100%',
                },
            }}
        >
            <Typography fontSize={'12px'} fontWeight={800}>
                {p('clocked_in')}
            </Typography>
            {clockedInHourLine.ArbeidsTypeNavn && clockedInHourLine.AvdelingNavn && (
                <>
                    <Box
                        width={'100%'}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'start'}
                        alignItems={'center'}
                    >
                        <FontAwesomeIcon width={'14px'} icon={faClock} color={theme.palette.primary.main} />
                        <Typography
                            textOverflow={'ellipsis'}
                            maxWidth={'95%'}
                            ml={'5px'}
                            fontSize={'12px'}
                            fontWeight={600}
                        >
                            {clockedInHourLine.ArbeidsTypeNavn} -
                        </Typography>
                        <Typography textOverflow={'ellipsis'} maxWidth={'95%'} ml={'5px'} fontSize={'12px'}>
                            {clockedInHourLine.AvdelingNavn}
                        </Typography>
                    </Box>
                </>
            )}
            {clockedInHourLine.ProsjektNavn && (
                <TreeBranch
                    sx={{ maxWidth: '95%' }}
                    title={
                        <>
                            <Typography textOverflow={'ellipsis'} maxWidth={'95%'} fontSize={'12px'}>
                                {clockedInHourLine.ProsjektId} {clockedInHourLine.ProsjektNavn}
                            </Typography>
                            {showHoursBudgetOnProject && (
                                <HoursBudgetBar
                                    hoursUsed={sumHoursProject.sumAntallTimer}
                                    hoursBudget={sumHoursProject.timebudsjett}
                                />
                            )}
                        </>
                    }
                >
                    {clockedInHourLine.ProsjektLinjeId && (
                        <TreeBranch
                            sx={{ width: '100%' }}
                            title={
                                <>
                                    <Typography maxWidth={'100%'} noWrap fontSize={'12px'} fontWeight={600}>
                                        {clockedInHourLine.ProsjektLinjeId} {clockedInHourLine.ProsjektLinjeNavn}
                                    </Typography>
                                    {showHoursBudgetOnJob && (
                                        <HoursBudgetBar
                                            sx={{
                                                maxWidth: '100%',
                                            }}
                                            hoursUsed={sumHoursJob.sumAntallTimer}
                                            hoursBudget={sumHoursJob.timebudsjett}
                                        />
                                    )}
                                </>
                            }
                        >
                            {clockedInHourLine.AktivitetId && (
                                <TreeBranch
                                    title={
                                        <>
                                            <Typography maxWidth={'100%'} noWrap fontSize={'12px'} fontWeight={600}>
                                                {clockedInHourLine.AktivitetId} {clockedInHourLine.AktivitetNavn}
                                            </Typography>
                                            {showHoursBudgetOnActivity && (
                                                <HoursBudgetBar
                                                    hoursUsed={sumHoursActivity.sumAntallTimer}
                                                    hoursBudget={sumHoursActivity.timebudsjett}
                                                />
                                            )}
                                        </>
                                    }
                                />
                            )}
                        </TreeBranch>
                    )}
                </TreeBranch>
            )}
        </Paper>
    );
};
