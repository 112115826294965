import { useContext } from 'react';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { Box, Checkbox, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import TbButton from '$components/muiBien/TbButton';
import { AnsattPanelFerieEntry } from '$helpers/types';
import { DateText } from '../DateText';
import { FerieBehandlingContext, getOnskeStatus } from './FerieBehandlingTab';
import TriStateToggle from './TriStateToggle';
type Props = {
    ferie: AnsattPanelFerieEntry;
};

export const FerieBehandlingDataRow = ({ ferie }: Props) => {
    const { updates, setUpdates } = useContext(FerieBehandlingContext);
    const theme = useTheme();
    const p = usePhrases('meny');
    const shouldShowFlyttShiftMessage =
        updates.some((update) => update.Id === ferie.Id) && ferie.ErPlanlagt && ferie.HarVakt;
    return (
        <Box
            width={'100%'}
            key={ferie.Date}
            display={'grid'}
            gridTemplateColumns={'100px 1fr'}
            gridTemplateAreas={'"dato onsk"'}
            mb={'5px'}
            minHeight={'35px'}
        >
            <DateText ferie={ferie} />
            {!ferie.ErOnskeAvslatt &&
            !ferie.OnskeRequiresAction &&
            new Date(ferie.Date) < new Date(Date.now()) &&
            !updates.some((f) => f.Id === ferie.Id) ? (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridColumn={'onsk'}>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{
                            alignSelf: 'center',
                            gridColumn: 'onsk',
                            color:
                                theme.palette.mode === 'dark'
                                    ? theme.palette.success.light
                                    : theme.palette.success.dark,
                        }}
                    >
                        {p('vacation_accepted_and_in_the_past')}
                    </Typography>
                    <TbButton
                        icon={faEdit}
                        onClick={() =>
                            setUpdates([
                                ...updates,
                                {
                                    Id: ferie.Id,
                                    KeepShift: false,
                                    Status: getOnskeStatus(ferie),
                                },
                            ])
                        }
                    />
                </Box>
            ) : ferie.ErOnskeAvslatt &&
              new Date(ferie.Date) < new Date(Date.now()) &&
              !updates.some((f) => f.Id === ferie.Id) ? (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridColumn={'onsk'}>
                    <Typography
                        fontSize={12}
                        fontWeight={600}
                        sx={{
                            alignSelf: 'center',
                            gridColumn: 'onsk',
                            color: theme.palette.mode === 'dark' ? theme.palette.error.light : theme.palette.error.dark,
                        }}
                    >
                        {p('request_denied_and_in_the_past')}
                    </Typography>
                    <TbButton
                        icon={faEdit}
                        onClick={() =>
                            setUpdates([
                                ...updates,
                                {
                                    Id: ferie.Id,
                                    KeepShift: false,
                                    Status: getOnskeStatus(ferie),
                                },
                            ])
                        }
                    />
                </Box>
            ) : (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridColumn={'onsk'}>
                    <TriStateToggle ferie={ferie} selectedValue={getOnskeStatus(ferie)} />
                </Box>
            )}
            {shouldShowFlyttShiftMessage && (
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} gridColumn={'1 / -1'}>
                    <Typography fontSize={12} fontWeight={600}>
                        {p('move_shift_to_ikke_tildelt')}
                    </Typography>
                    <Checkbox
                        onChange={(event) =>
                            setUpdates(
                                updates.map((update) => {
                                    if (update.Id === ferie.Id) {
                                        return {
                                            ...update,
                                            keepShift: event.target.checked,
                                        };
                                    }
                                    return update;
                                })
                            )
                        }
                    />
                </Box>
            )}
        </Box>
    );
};
