import { createSignalRContext } from 'react-signalr';
import { useAtomValue } from 'jotai';
import { apiEventsUrl } from '$helpers/api';
import { drawerStateAtom } from './DrawerAtoms';

type Props = {
    children: React.ReactNode;
};

export const SignalRContext = createSignalRContext();

const SignalREventHubContext = ({ children }: Props) => {
    const signalRToken = '6YPk79Q/jlEaIsCvQEwvZAzzA4+3WHtB5lWKKcvlX+Y=';
    const signalRUrl = apiEventsUrl + '/hub/event';
    const { me } = useAtomValue(drawerStateAtom);

    return (
        <SignalRContext.Provider
            connectEnabled={!!me}
            accessTokenFactory={() => signalRToken}
            dependencies={[signalRToken]} //remove previous connection and create a new connection if changed
            url={signalRUrl}
        >
            {children}
        </SignalRContext.Provider>
    );
};

export default SignalREventHubContext;
