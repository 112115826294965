import { useMemo } from 'react';
import { faArrowRightFromBracket, faArrowRightToBracket } from '@fortawesome/pro-regular-svg-icons';
import { Box, darken, lighten, Paper, Skeleton, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import { baseUrl } from '$helpers/helpers';
import { useStampInVersion } from '$hooks/useStampInVersion';
import { LinkWithIcon } from './LinkWithIcon';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const urls = {
    stampIn: `${baseUrl}/stemple-inn`,
    stampOut: `${baseUrl}/stemple-ut`,
    stampInOld: `${baseUrl}/stemple4.asp?handling=inn`,
    stampOutOld: `${baseUrl}/stemple4.asp?handling=ut`,
};

enum actionEnum {
    stampIn = 'inn',
    stampOut = 'ut',
}

export const StampButton = () => {
    const { employeeId, me, employee } = useAtomValue(drawerStateAtom);
    const { stampInVersion, isLoading, error } = useStampInVersion();
    const theme = useTheme();
    const p = usePhrases('meny');

    const isMe = me?.Id === employeeId;

    const action = useMemo(() => {
        if (stampInVersion === 2) {
            return {
                action: employee?.Innstemplet ? actionEnum.stampOut : actionEnum.stampIn,
                href: employee?.Innstemplet ? urls.stampOut : urls.stampIn,
            };
        } else {
            return {
                action: employee?.Innstemplet ? actionEnum.stampOut : actionEnum.stampIn,
                href: employee?.Innstemplet ? urls.stampOutOld : urls.stampInOld,
            };
        }
    }, [stampInVersion, employee]);

    return (
        <Paper
            component={Box}
            square
            sx={{
                width: '100%',
                padding: '5px',
                mb: '8px',
                zIndex: 0,
                borderWidth: '0px 1px 1px 1px',
                borderStyle: 'solid',
                borderColor:
                    theme.palette.mode === 'light'
                        ? darken(theme.palette.background.default, 0.05)
                        : lighten(theme.palette.background.default, 0.05),
                '&::before': { display: 'none' },
                '&.MuiAccordion-root.Mui-disabled': {
                    backgroundColor: theme.palette.background.paper,
                    color: theme.palette.grey[500],
                },
            }}
        >
            {isLoading ? (
                <Skeleton variant="rectangular" width={'100%'} height={40} />
            ) : error ? null : (
                isMe && (
                    <LinkWithIcon
                        href={action.href}
                        linkText={action.action === actionEnum.stampIn ? p('stamp_in') : p('stamp_out')}
                        bgColor={action.action === actionEnum.stampIn ? 'success' : 'error'}
                        customIcon={
                            action.action === actionEnum.stampIn ? faArrowRightToBracket : faArrowRightFromBracket
                        }
                    />
                )
            )}
        </Paper>
    );
};
