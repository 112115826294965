import { Box, styled } from '@mui/material';

type Props = {
    color?: string;
    size?: number | string;
};

const PulsatingCircle = ({ color, size = 1 }: Props) => {
    const StyledBox = styled(Box)(({ theme }) => ({
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        position: 'relative',
        '&::after': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 2.5s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    return <StyledBox width={size} height={size} borderRadius="100%" color={color} bgcolor={color} />;
};

export default PulsatingCircle;
