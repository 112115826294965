import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getRemainingVacationDays } from '$helpers/api';
import { rootCacheKey } from './useFerieInfo';

type Props = {
    databaseId: string;
    employeeId: number;
    date: string;
};

const useFerieRemaining = ({databaseId, employeeId, date} : Props) => {
    const cacheKey = [...rootCacheKey, { databaseId, employeeId, date }];
    
    const { data: vacationNumbers, isLoading } = useQuery({
        queryKey: cacheKey,
        queryFn: () => getRemainingVacationDays(employeeId, date),
        enabled: true,
        refetchOnMount: 'always',
        placeholderData: keepPreviousData,
    });

    return { vacationNumbers, isLoading };
};

export default useFerieRemaining;

