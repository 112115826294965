import { keepPreviousData } from '@tanstack/query-core';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { getAnsattInfoSettings } from '$helpers/api';
import { AnsattInfoTabSettings } from '$helpers/types';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const rootCacheKey = ['settings'];
export const useSettings = () => {
    const { openTab } = useAtomValue(drawerStateAtom);

    const { data, isLoading, isFetching, error } = useQuery({
        queryKey: [...rootCacheKey, { openTab }],
        queryFn: () => {
            switch (openTab) {
                default:
                    return getAnsattInfoSettings();
            }
        },
        enabled: true,
        refetchOnMount: 'always',
        placeholderData: keepPreviousData,
    });

    const settings: AnsattInfoTabSettings = data ?? {
        AnsattInfoOpen: true,
        InnstempletOpen: true,
        KontaktInfoOpen: true,
        MerInfoOpen: true,
        PlanlagtOpen: true,
    };

    return {
        settings,
        isLoading: isLoading || isFetching,
        error,
    };
};
