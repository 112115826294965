import { useContext } from 'react';
import { Theme } from '@emotion/react';
import { faRotateLeft, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faTrash as solidTrash } from '@fortawesome/pro-solid-svg-icons';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faHourglass } from '@fortawesome/pro-solid-svg-icons/faHourglass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SxProps, ToggleButton, ToggleButtonGroup, useTheme } from '@mui/material';
import TbButton from '$components/muiBien/TbButton';
import { AnsattPanelFerieEntry, OnskeStatus } from '$helpers/types';
import { FerieBehandlingContext, getOnskeStatus } from './FerieBehandlingTab';

type Props = {
    sx?: SxProps<Theme>;
    ferie: AnsattPanelFerieEntry;
    selectedValue: OnskeStatus;
};

const TriStateToggle = ({ sx, ferie, selectedValue }: Props) => {
    const theme = useTheme();
    const { handleOnClick, handleOnClickDelete, handleOnClickReset, toDelete, updates } =
        useContext(FerieBehandlingContext);

    return (
        <Box
            gridArea={'onsk'}
            alignItems={'center'}
            className="tri-state-toggle-wrapper"
            sx={{
                ...sx,
                display: 'flex',
                flex: 1,
            }}
        >
            <ToggleButtonGroup
                exclusive
                value={selectedValue}
                aria-label="Platform"
                sx={{ color: theme.palette.primary.contrastText, transition: 'all 0.3s ease' }}
            >
                <ToggleButton
                    value={OnskeStatus.Waiting}
                    sx={{
                        backgroundColor:
                            selectedValue === OnskeStatus.Waiting ? '#ffaa4d !important' : 'transparent !important',
                    }}
                    onClick={(e) => handleOnClick(e, { ferie: ferie, status: OnskeStatus.Waiting })}
                    className="Waiting"
                >
                    <FontAwesomeIcon icon={faHourglass} />
                </ToggleButton>
                <ToggleButton
                    sx={{
                        color: selectedValue === OnskeStatus.Rejected ? '#fff !important' : theme.palette.grey[600],
                        backgroundColor:
                            selectedValue === OnskeStatus.Rejected ? '#cd333b !important' : 'transparent !important',
                    }}
                    value={OnskeStatus.Rejected}
                    onClick={(e) => handleOnClick(e, { ferie: ferie, status: OnskeStatus.Rejected })}
                    className="Rejected"
                >
                    <FontAwesomeIcon icon={faBan} />
                </ToggleButton>
                <ToggleButton
                    sx={{
                        color: selectedValue === OnskeStatus.Approved ? '#fff !important' : theme.palette.grey[600],
                        backgroundColor:
                            selectedValue === OnskeStatus.Approved ? '#2d9f79 !important' : 'transparent !important',
                    }}
                    value={OnskeStatus.Approved}
                    onClick={(e) => handleOnClick(e, { ferie: ferie, status: OnskeStatus.Approved })}
                    className="Approved"
                >
                    <FontAwesomeIcon icon={faCheck} />
                </ToggleButton>
            </ToggleButtonGroup>
            {ferie.OnskeRequiresAction && (
                <TbButton
                    icon={toDelete.includes(ferie.Id) ? solidTrash : faTrash}
                    sx={{ color: theme.palette.error.main }}
                    onClick={(e) => handleOnClickDelete(e, { ferie: ferie })}
                />
            )}
            {updates.some((f) => f.Id === ferie.Id) && (
                <TbButton
                    icon={faRotateLeft}
                    onClick={(e) => handleOnClickReset(e, { ferie: ferie, status: getOnskeStatus(ferie) })}
                />
            )}
        </Box>
    );
};

export default TriStateToggle;
