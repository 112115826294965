export function memoize(func) {
    var cache = {};
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var key = JSON.stringify(args);
        var cached = cache[key];
        if (cached !== undefined) {
            return cached;
        }
        return cache[key] = func.apply(void 0, args);
    };
}
;
export default memoize;
