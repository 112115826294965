import { Avatar, Typography } from '@mui/material';

type AvatarProps = {
    imageUrl: string | null | undefined;
    firstName: string | null | undefined;
    lastName: string | null | undefined;
};

export const AvatarOrInitials = ({ imageUrl, firstName, lastName }: AvatarProps) => {
    const initials = getInitials(firstName, lastName);
    const color = stringToColour(`${firstName} ${lastName}`);
    return imageUrl ? (
        <Avatar
            sx={{ width: '56px', height: '56px', marginRight: '15px', bgcolor: color }}
            alt={firstName + ' ' + lastName}
            src={imageUrl}
        />
    ) : (
        <Avatar
            sx={{
                width: '56px',
                height: '56px',
                marginRight: '15px',
                bgcolor: color,
            }}
            alt={firstName + ' ' + lastName}
        >
            <Typography textAlign={'center'} fontWeight={600} fontSize={'25px'}>
                {initials}
            </Typography>
        </Avatar>
    );
};

const stringToColour = (str: string | null | undefined) => {
    str = (str ?? '').trim().toUpperCase().replace(/\s/g, '');
    if (!str) return '#f0f2f2';
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    const saturation = 0.65;
    const lightness = 0.8;
    for (let i = 0; i < 3; i++) {
        const baseValue = (hash >> (i * 8)) & 0xff;
        const newValue = Math.floor(baseValue * saturation + lightness * 255 * (1 - saturation));
        colour += newValue.toString(16).padStart(2, '0').slice(-2);
    }
    return colour;
};

const getInitials = (firstName: string | null | undefined, lastName?: string | null | undefined) => {
    if (!firstName) return undefined;
    const first = firstName.charAt(0);
    const last = lastName?.charAt(0) ?? '';
    return first + last;
};