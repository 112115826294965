import { Box, Skeleton, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { decimalColorToHexColor, getContrastYIQ } from '$helpers/colorHelper';
import useTodaysShifts from '$hooks/useTodaysShifts';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const TodaysShifts = () => {
    const { employeeId } = useAtomValue(drawerStateAtom);
    const { todaysShifts, isLoadingTodaysShifts } = useTodaysShifts({
        employeeId,
    });
    const p = usePhrases('meny');

    return (
        <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
            margin={1}
            gap={1}
        >
            {isLoadingTodaysShifts ? (
                <Skeleton variant="rectangular" width="100%" height={'12px'} />
            ) : todaysShifts.length === 0 ? (
                <Typography fontSize={'12px'}>{p('no_shifts_emp_drawer')}</Typography>
            ) : (
                todaysShifts.map((shift) => {
                    const shiftColor = decimalColorToHexColor(shift?.ArbeidsTypeWebBg);
                    const textColor = getContrastYIQ(shiftColor);
                    return (
                        <Box
                            key={shift.Id}
                            minHeight={'28px'}
                            padding={'4px'}
                            width={'100%'}
                            sx={{
                                bgcolor: shiftColor,
                                borderRadius: '5px',
                                color: textColor,
                            }}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                        >
                            <Box
                                display={'grid'}
                                gridTemplateColumns={'1fr 80px'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    justifyContent={'start'}
                                    overflow={'hidden'}
                                >
                                    <Typography
                                        maxWidth={'70%'}
                                        noWrap
                                        fontSize={'12px'}
                                        fontWeight={800}
                                        color={'inherit'}
                                    >
                                        {shift.ArbeidsTypeNavn ?? p('unknown_work_type')}
                                    </Typography>
                                    <Typography noWrap ml={'4px'} fontSize={'12px'} color={'inherit'}>
                                        - {shift.AvdelingNavn ?? p('unknown_department')}
                                    </Typography>
                                </Box>

                                <Typography
                                    textAlign={'right'}
                                    fontSize={'12px'}
                                    fontWeight={600}
                                    color={'inherit'}
                                >
                                    {format(new Date(shift.FraKlokken ?? ''), 'HH:mm')} -{' '}
                                    {format(new Date(shift.TilKlokken ?? ''), 'HH:mm')}
                                </Typography>
                            </Box>
                            {shift.ProsessTypeNavn && (
                                <Typography noWrap maxWidth={'80%'} mt={'-4px'} fontSize={'11px'} color={'inherit'}>
                                    {shift.ProsessTypeNavn}
                                </Typography>
                            )}
                        </Box>
                    );
                })
            )}
        </Box>
    );
};

export default TodaysShifts;
