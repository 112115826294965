export type EmployeeDrawerConfig = {
    openPanels?: string[];
    zIndex?: number;
    top?: number;
    year?: number;
};

export type Ansatt = {
    Id: number;
    Bilde?: string | null;
    Fornavn?: string | null;
    Etternavn?: string | null;
    Epost?: string | null;
    Mobil?: string | null;
    TlfPrivat?: string | null;
    AvdelingId?: string | null;
    AvdelingNavn?: string | null;
};

export type Me = {
    FirmaNavn: string;
    FirmaId: number;
    Id: number;
    LederFor: string[];
    Fornavn: string;
    Etternavn: string;
    ErAdmin: boolean;
    PostId: string;
    ErAvdelingsleder: boolean;
    Avdeling: string;
    ErBruker: boolean;
    ErBedrift: boolean;
    HarAdministrativTilgangTilAvvik: boolean;
    HarAdministrativTilgangTilKvalitetsmanual: boolean;
    LoggetInnMedUserKey: boolean;
    MalId: number;
};

export type Timelinje = {
    Id: number;
    FraKlokken: string;
    AnsattId: number;
    ArbeidsTypeId: string | null;
    ArbeidsTypeNavn: string | null;
    AvdelingId: string | null;
    AvdelingNavn: string | null;
    ProsjektId: string | null;
    ProsjektPostId: number | null;
    ProsjektNavn: string | null;
    ProsjektLinjeId: string | null;
    ProsjektLinjePostId: number | null;
    ProsjektLinjeNavn: string | null;
    AktivitetId: string | null;
    AktivitetPostId: number | null;
    AktivitetNavn: string | null;
};

export type SumPerEnhet = {
    postId: number;
    sumAntallTimer: number;
    timebudsjett: number;
};

export type Bemanningsplan = {
    Id: number;
    FraKlokken: string;
    TilKlokken: string;
    AntallTimer: number;
    ArbeidsTypeId?: string | null;
    ArbeidsTypeNavn?: string | null;
    ArbeidsTypeWebBg?: string | null;
    AvdelingId?: string | null;
    AvdelingNavn?: string | null;
    ProsessTypeId?: number | null;
    ProsessTypeNavn?: string | null;
};

export type AnsattPanelFerieEntry = {
    Id: number;
    Date: string; // As Date
    ErOnsket: boolean;
    OnskeRequiresAction: boolean;
    ErOnskeAvslatt: boolean;

    ErPlanlagt: boolean;
    PlanlagtRequiresAction: boolean;

    ErBehandlet: boolean;
    behandletRequiresAction: boolean;

    HarVakt: boolean;
};

export type AnsattPanelFerie = {
    Uke: number;
    FerieEntries: AnsattPanelFerieEntry[];
};

export type RequestStatus = {
    Approved: number;
    Rejected: number;
    Pending: number;
};
export type UsedVacationDays = {
    Total: number;
};
export type VacationNumberAndEmployees = {
    Count: number;
    Employees: Record<number, string[]>;
    Total: number;
    RequestStatus: RequestStatus | null;
};
export type VacationNumbersResponse = {
    PlannedVacationDaysThisYear: VacationNumberAndEmployees;
    RemainingVacationDaysThisYear: VacationNumberAndEmployees;
    PendingVacationRequestsThisYear: VacationNumberAndEmployees;
    UsedVacationDaysThisYear: UsedVacationDays;
};

export type Fridag = {
    Date: string;
    Description: string;
    IsHoliday: boolean;
};

export type Targets = {
    Users?: string[];
    Roles?: string[];
    Channels?: string[];
    Departments?: string[];
    All?: boolean;
};

export enum SenderTypeEnum {
    User,
    Customer,
    System,
}

export type NotificationType = {
    Message?: string;
    Url?: string;
    Color?: string;
    Icon?: string;
    Channel?: string;
    SubChannel?: string;
    Targets?: Targets;
    SenderType?: SenderTypeEnum;
};

// Onsker
export enum OnskeStatus {
    Waiting = 0,
    Approved = 1,
    Rejected = 2,
}

export type BehandleOnskeType = {
    Id: number;
    Status: OnskeStatus;
    KeepShift: boolean;
};

export type BehandleOnskePayload = {
    AnsattId: number;
    FerieOnsker: BehandleOnskeType[];
};

export type DeleteOnskePayload = {
    OnskeIds: number[];
};

export type CreateOnskeType = {
    AnsattId: number;
    Dates: string[];
};

export type CreateError = {
    AnsattId: number;
    Name: string;
    ErrorMessage: string;
};

export type CreateOnskeResult = {
    CreatedData: Onske[];
    Errors: CreateError[];
};

export type Onske = {
    Id: number;
    Dato: string;
    Status: OnskeStatus;
    Type?: number | null;
};
