import { faClock as faClockOutline } from '@fortawesome/pro-regular-svg-icons';
import { faClock, faEnvelope, faInfoCircle, faLink, faMemoCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';
import {
    postAnsattInfoOpen,
    postInnstempletOpen,
    postKontaktInfoOpen,
    postMerInfoOpen,
    postPlanlagtOpen,
} from '$helpers/api';
import { useSettings } from '$hooks/useSettings';
import useTodaysShifts from '$hooks/useTodaysShifts';
import { ClockedIn } from './ClockedIn';
import { ContactInfo } from './ContactInfo';
import { CustomAccordion } from './CustomAccordion';
import { EmployeeInfo } from './EmployeeInfo';
import { InfoLinks } from './InfoLinks';
import { StampButton } from './StampButton';
import TodaysShifts from './TodaysShifts';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const InfoTab = () => {
    const theme = useTheme();
    const p = usePhrases('meny');
    const { employee, employeeId, hasAdminOrAvdLederAccess, me } = useAtomValue(drawerStateAtom);
    const { settings, isLoading } = useSettings();
    const { todaysShifts, isLoadingTodaysShifts } = useTodaysShifts({
        employeeId,
    });

    const disableUrls = me?.Id !== employee?.Id && !hasAdminOrAvdLederAccess;
    const isMe = me?.Id === employeeId;
    return (
        <Box display="flex" flexDirection="column" margin={'15px'}>
            {isLoading ? (
                <CircularProgress />
            ) : (
                <>
                    <Typography fontSize={'14px'} fontWeight={800}>
                        {p('info')}
                    </Typography>
                    <CustomAccordion
                        summary={{
                            title: p('employee_info'),
                            icon: faInfoCircle,
                            iconColor: theme.palette.primary.main,
                        }}
                        details={<EmployeeInfo />}
                        onChange={async (open: boolean) => {
                            await postAnsattInfoOpen(open);
                        }}
                        defaultExpanded={settings.AnsattInfoOpen}
                    />
                    <CustomAccordion
                        summary={{
                            title: p('contact_info'),
                            icon: faEnvelope,
                            iconColor: theme.palette.primary.main,
                        }}
                        onChange={async (open: boolean) => {
                            await postKontaktInfoOpen(open);
                        }}
                        details={<ContactInfo />}
                        defaultExpanded={settings.KontaktInfoOpen}
                    />
                    <Typography fontSize={'14px'} fontWeight={800} mt={'10px'}>
                        {p('status')}
                    </Typography>
                    <CustomAccordion
                        summary={{
                            title: employee?.Innstemplet ? p('clocked_in') : p('not_clocked_in'),
                            icon: employee?.Innstemplet ? faClock : faClockOutline,
                            iconColor: employee?.Innstemplet ? theme.palette.success.main : theme.palette.primary.main,
                        }}
                        onChange={async (open: boolean) => {
                            await postInnstempletOpen(open);
                        }}
                        details={<ClockedIn />}
                        disabled={!employee?.Innstemplet}
                        defaultExpanded={settings.InnstempletOpen}
                    />
                    {false && <StampButton />}
                    <CustomAccordion
                        summary={{
                            title: todaysShifts.length > 0 ? p('planned_shifts_today') : p('no_planned_shifts_today'),
                            icon: faMemoCircleInfo,
                            iconColor: theme.palette.primary.main,
                            iconMarginLeft: '2px',
                            iconMarginRight: '-2px',
                        }}
                        onChange={async (open: boolean) => {
                            await postPlanlagtOpen(open);
                        }}
                        disabled={isLoadingTodaysShifts || todaysShifts.length === 0}
                        details={<TodaysShifts />}
                        defaultExpanded={settings.PlanlagtOpen}
                    />
                    <Typography fontSize={'14px'} fontWeight={800} mt={'10px'}>
                        {p('more_info')}
                    </Typography>
                    <CustomAccordion
                        summary={{
                            title: disableUrls ? p('no_links') : p('useful_links'),
                            icon: faLink,
                            iconColor: theme.palette.primary.main,
                        }}
                        onChange={async (open: boolean) => {
                            await postMerInfoOpen(open);
                        }}
                        details={<InfoLinks />}
                        disabled={disableUrls}
                        defaultExpanded={settings.MerInfoOpen}
                    />
                </>
            )}
        </Box>
    );
};
