export var apiUrl = function (repo) {
    var host = window.location.host;
    switch (host) {
        case 'min.tidsbanken.net':
            return "https://api-" + repo + ".tidsbanken.net";
        default:
            return "https://api-" + repo + "-sandkasse.tidsbanken.net";
    }
};
export default apiUrl;
