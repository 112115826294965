// Remove all cached phrase domains
export function clearPhrases() {
    // List of keys to remove
    var keys = [];
    // Key prefix for phrase domain caches
    var prefix = "phrases_";
    // Loop over all localStorage items to find phrase caches
    for (var i = 0; i < localStorage.length; ++i) {
        // Get current localStorage key
        var key = localStorage.key(i);
        // For type safety
        if (key === null) {
            break;
        }
        // Check if current key is for a phrase cache
        if (key.substring(0, prefix.length) === prefix) {
            // Add to list of keys to remove
            keys.push(key);
        }
    }
    // Remove all relevant key/value pairs from localStorage
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        localStorage.removeItem(key);
    }
}
