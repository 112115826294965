import React, { Dispatch } from 'react';
import { faAddressCard, faCalendar, faRightFromBracket, faTreePalm } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tab, Tabs, Tooltip, Typography, useTheme } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue } from 'jotai';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

type Props = {
    tabValue: number;
    setTabValue: Dispatch<React.SetStateAction<number>>;
};

const TabSelector = ({ tabValue, setTabValue }: Props) => {
    const { hasFerieAccess, me, employeeId } = useAtomValue(drawerStateAtom);
    const p = usePhrases('meny');
    const theme = useTheme();

    const handleOnChange = (_e: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    return (
        <Tabs
            value={tabValue}
            onChange={handleOnChange}
            sx={{
                borderTop: `1px solid ${theme.palette.divider}`,
                '& .MuiTabs-flexContainer': {
                    justifyContent: 'space-evenly',
                    width: '100%',
                    height: '60px',
                },
            }}
            variant="scrollable"
            scrollButtons="auto"
        >
            <Tab
                label={<Typography fontSize={'12px'}>{p('calendar')}</Typography>}
                icon={<FontAwesomeIcon icon={faCalendar} size="2x" style={{ width: '20px', height: '20px' }} />}
                sx={{
                    padding: 0,
                    margin: 0,
                    minHeight: '48px',
                }}
            />
            <Tab
                label={<Typography fontSize={'12px'}>{p('employee_info')}</Typography>}
                icon={<FontAwesomeIcon icon={faAddressCard} size="2x" style={{ width: '20px', height: '20px' }} />}
                sx={{
                    padding: 0,
                    margin: 0,
                    minHeight: '48px',
                }}
            />
            {hasFerieAccess ? (
                <Tab
                    label={<Typography fontSize={'12px'}>{p('vacation')}</Typography>}
                    icon={<FontAwesomeIcon icon={faTreePalm} size="2x" style={{ width: '20px', height: '20px' }} />}
                    sx={{
                        padding: 0,
                        margin: 0,
                        minHeight: '48px',
                    }}
                />
            ) : (
                <Tooltip title={p('vacation_tab_no_access')}>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'} sx={{ minHeight: '48px' }}>
                        <Tab
                            label={<Typography fontSize={'12px'}>{p('vacation')}</Typography>}
                            disabled
                            icon={
                                <FontAwesomeIcon
                                    icon={faTreePalm}
                                    size="2x"
                                    style={{ width: '20px', height: '20px' }}
                                />
                            }
                            sx={{
                                padding: 0,
                                margin: 0,
                                minHeight: '48px',
                            }}
                        />
                    </Box>
                </Tooltip>
            )}
            {me && me?.Id === employeeId && !me.LoggetInnMedUserKey && (
                <Tab
                    label={<Typography fontSize={'12px'}>{p('logout')}</Typography>}
                    icon={
                        <FontAwesomeIcon
                            icon={faRightFromBracket}
                            size="2x"
                            style={{ width: '20px', height: '20px' }}
                        />
                    }
                    sx={{
                        padding: 0,
                        margin: 0,
                        minHeight: '48px',
                    }}
                />
            )}
        </Tabs>
    );
};

export default TabSelector;
