import {
    faDownLeftAndUpRightToCenter,
    faUpRightAndDownLeftFromCenter,
    faXmarkLarge,
} from '@fortawesome/pro-solid-svg-icons';
import { Box } from '@mui/material';
import { useAtomValue, useSetAtom } from 'jotai';
import TbButton from '../muiBien/TbButton';
import { AvatarDropdown } from './AvatarDropdown';
import { EmployeeSelectorVirtualized } from './EmployeeSelectorVirtualized';

import { drawerStateAtom, fullViewAtom } from '~src/contextsAndAtoms/DrawerAtoms';

export const almostBlack = '#29343d';

type Props = {
    close: () => void;
};

const TopBar = ({ close }: Props) => {
    const { fullView } = useAtomValue(drawerStateAtom);
    const setFullView = useSetAtom(fullViewAtom);

    return (
        <Box>
            <Box display="flex" bgcolor={almostBlack} alignItems="center" justifyContent="space-between" p={0}>
                {false && (
                    <TbButton
                        sx={{ color: 'white', m: '0.5rem' }}
                        icon={fullView ? faDownLeftAndUpRightToCenter : faUpRightAndDownLeftFromCenter}
                        onClick={() => setFullView(!fullView)}
                    />
                )}

                <AvatarDropdown />

                <EmployeeSelectorVirtualized />
                <TbButton sx={{ color: 'white', m: '5px' }} icon={faXmarkLarge} onClick={close} />
            </Box>
        </Box>
    );
};

export default TopBar;
