import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Ansatt } from '$helpers/types';

import { getEmployeeList } from '~src/helpers/api';

type Props = {
    enabled: boolean;
};

export const rootCacheKey = ['employeelist'];

export const useEmployeeList = ({ enabled }: Props) => {
    const cacheKey = [...rootCacheKey];

    const { data, isLoading, isFetching } = useQuery({
        queryKey: cacheKey,
        queryFn: getEmployeeList,
        enabled: enabled,
        staleTime: 1000 * 60 * 60,
        placeholderData: keepPreviousData,
    });

    const employeeList: Ansatt[] = data ?? [];

    return {
        employeeList: employeeList,
        isLoading: enabled && (isLoading || isFetching),
    };
};
