import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { getStampInVersion } from '$helpers/api';

export const useStampInVersion = () => {
    const { data, isLoading, isFetching, error } = useQuery({
        queryKey: ['stampInVersion'],
        queryFn: getStampInVersion,
        enabled: true,
        placeholderData: keepPreviousData,
    });

    const stampInVersion: number = data?.StampInVersion ?? 2;

    return {
        stampInVersion,
        isLoading: isLoading || isFetching,
        error,
    };
};
