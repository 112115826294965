import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Box, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import TbButton from '$components/muiBien/TbButton';

import { yearAtom } from '~src/contextsAndAtoms/FerieAtoms';

export const Year = () => {
    const [year, setYear] = useAtom(yearAtom);
    return (
        <Box
            display="flex"
            flexDirection="row"
            width={'100%'}
            justifyContent={'center'}
            alignItems={'center'}
            sx={{ placeSelf: 'center' }}
        >
            <TbButton icon={faAngleLeft} onClick={() => setYear(year - 1)} />
            <Typography variant="h6" fontWeight={600} textAlign={'center'}>
                {year}
            </Typography>
            <TbButton icon={faAngleRight} onClick={() => setYear(year + 1)} />
        </Box>
    );
};
