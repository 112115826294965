import { useEffect, useState } from 'react';
import { debounce } from '$helpers/debounce';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(
        /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    );

    useEffect(() => {
        const checkIsMobile = () => {
            setIsMobile(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
        };
        checkIsMobile();
        const debouncedCheckIsMobile = debounce(checkIsMobile, 15);

        window.addEventListener('resize', debouncedCheckIsMobile);

        return () => {
            window.removeEventListener('resize', debouncedCheckIsMobile);
        };
    }, []);

    return isMobile;
};

export default useIsMobile;
