import { createContext, useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Typography, useTheme } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { usePhrases } from '@tidsbanken/phrases';
import { format } from 'date-fns';
import { useAtomValue } from 'jotai';
import { behandleOnskeRequest, deleteOnskeRequest, postNotification } from '$helpers/api';
import {
    AnsattPanelFerie,
    AnsattPanelFerieEntry,
    DeleteOnskePayload,
    OnskeStatus,
    BehandleOnskeType,
} from '$helpers/types';
import { ferieTabStateAtom } from '../../../../contextsAndAtoms/FerieAtoms';
import { FerieContext } from '../FerieTab';
import { Year } from '../Year';
import { FerieBehandlingDataRow } from './FerieBehandlingDataRow';
import { FerieBehandlingInfo } from './FerieBehandlingInfo';
import { GroupToggle } from './GroupToggle';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';

const filterBehandletFerie = (
    ferie: AnsattPanelFerie[] | undefined,
    visBehandlede: boolean,
    updates: BehandleOnskeType[],
    toDelete: number[]
) => {
    if (!ferie) return [];
    if (visBehandlede)
        return ferie
            .map((entry) => {
                const filteredEntries = entry.FerieEntries.filter((ferieEntry) => {
                    const isUpdated = updates.some((update) => update.Id === ferieEntry.Id);
                    const isDeleted = toDelete.some((id) => id === ferieEntry.Id);
                    return ferieEntry.ErOnsket || isUpdated || isDeleted;
                });
                return { ...entry, FerieEntries: filteredEntries };
            })
            .filter((entry) => entry.FerieEntries.length > 0);

    return ferie
        .map((entry) => {
            const filteredEntries = entry.FerieEntries.filter((ferieEntry) => {
                const isUpdated = updates.some((update) => update.Id === ferieEntry.Id);
                const isDeleted = toDelete.some((id) => id === ferieEntry.Id);
                return ferieEntry.ErOnsket && (ferieEntry.OnskeRequiresAction || isUpdated || isDeleted);
            });
            return { ...entry, FerieEntries: filteredEntries };
        })
        .filter((entry) => entry.FerieEntries.length > 0);
};

type FerieBehandlingContextType = {
    updates: BehandleOnskeType[];
    setUpdates: React.Dispatch<React.SetStateAction<BehandleOnskeType[]>>;
    toDelete: number[];
    handleOnClick: (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
            status: OnskeStatus;
        }
    ) => void;
    handleOnClickReset: (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
            status: OnskeStatus;
        }
    ) => void;
    handleOnClickDelete: (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
        }
    ) => void;
};

export const FerieBehandlingContext = createContext<FerieBehandlingContextType>({
    updates: [],
    setUpdates: () => {},
    toDelete: [],
    handleOnClick: () => {},
    handleOnClickReset: () => {},
    handleOnClickDelete: () => {},
});

export const getOnskeStatus = (ferie: AnsattPanelFerieEntry) => {
    switch (true) {
        case ferie.OnskeRequiresAction:
            return OnskeStatus.Waiting;
        case ferie.ErOnskeAvslatt:
            return OnskeStatus.Rejected;
        case ferie.PlanlagtRequiresAction || ferie.ErPlanlagt:
            return OnskeStatus.Approved;
        default:
            return OnskeStatus.Waiting;
    }
};

export const FerieBehandlingTab = () => {
    const { employee } = useAtomValue(drawerStateAtom);
    const [saving, setSaving] = useState<boolean>(false);
    const { cacheKey, isInitialLoading } = useAtomValue(ferieTabStateAtom);
    const { employeeId } = useAtomValue(drawerStateAtom);
    const { ferieInfo } = useContext(FerieContext);
    const [visBehandlede, setVisBehandlede] = useState<boolean>(false);
    const p = usePhrases('meny');
    const theme = useTheme();

    const [mutateData, setMutateData] = useState<AnsattPanelFerie[] | undefined>(ferieInfo);
    const queryClient = useQueryClient();
    const [updates, setUpdates] = useState<BehandleOnskeType[]>([]);
    const [toDelete, setToDelete] = useState<number[]>([]);
    useEffect(() => {
        setMutateData(ferieInfo);
    }, [ferieInfo]);

    const handleOnClick = (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
            status: OnskeStatus;
        }
    ) => {
        if (value.status === getOnskeStatus(value.ferie)) return;

        if (e) {
            setToDelete(toDelete.filter((id) => id !== value.ferie.Id));
            const update = { Id: value.ferie.Id, Status: value.status };
            if (toDelete.includes(value.ferie.Id)) setToDelete(toDelete.filter((id) => id !== value.ferie.Id));
            setUpdates((prevUpdates) => {
                const existingUpdateIndex = prevUpdates.findIndex((upd) => upd.Id === update.Id);
                if (existingUpdateIndex !== -1) {
                    const originalValue = ferieInfo
                        ?.find((entry) => entry.FerieEntries.some((ferie) => ferie.Id === update.Id))
                        ?.FerieEntries.find((ferie) => ferie.Id === update.Id);
                    if (getOnskeStatus(originalValue!) === update.Status) {
                        return prevUpdates.filter((upd) => upd.Id !== update.Id);
                    }

                    const updatedUpdates = [...prevUpdates];
                    updatedUpdates[existingUpdateIndex] = {
                        ...update,
                        KeepShift: updatedUpdates[existingUpdateIndex].KeepShift,
                    };
                    return updatedUpdates;
                } else {
                    return [
                        ...prevUpdates,
                        {
                            ...update,
                            KeepShift: false,
                        },
                    ];
                }
            });

            const uke = mutateData?.find((entry) => entry.FerieEntries.includes(value.ferie))?.Uke;
            setMutateData((prev) => {
                return prev?.map((entry) => {
                    if (entry.Uke === uke) {
                        return {
                            ...entry,
                            FerieEntries: entry.FerieEntries.map((ferie) => {
                                if (ferie.Date === value.ferie.Date) {
                                    return {
                                        ...ferie,
                                        OnskeRequiresAction: value.status === OnskeStatus.Waiting,
                                        PlanlagtRequiresAction: false,
                                        ErOnskeAvslatt: value.status === OnskeStatus.Rejected,
                                        ErPlanlagt: value.status === OnskeStatus.Approved,
                                    };
                                }
                                return ferie;
                            }),
                        };
                    }
                    return entry;
                });
            });
        }
    };

    const handleOnClickReset = (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
            status: OnskeStatus;
        }
    ) => {
        if (e) {
            setUpdates((prevUpdates) => prevUpdates.filter((upd) => upd.Id !== value.ferie.Id));
            const uke = mutateData?.find((entry) => entry.FerieEntries.includes(value.ferie))?.Uke;
            setMutateData((prev) => {
                return prev?.map((entry) => {
                    if (entry.Uke === uke) {
                        return {
                            ...entry,
                            FerieEntries: entry.FerieEntries.map((ferie) => {
                                if (ferie.Date === value.ferie.Date) {
                                    return {
                                        ...(ferieInfo
                                            ?.find((entry) =>
                                                entry.FerieEntries.some((ferie) => ferie.Id === value.ferie.Id)
                                            )
                                            ?.FerieEntries.find((ferie) => ferie.Id === value.ferie.Id) ?? value.ferie),
                                    };
                                }
                                return ferie;
                            }),
                        };
                    }
                    return entry;
                });
            });
        }
    };

    const handleOnClickDelete = (
        e: React.MouseEvent<HTMLElement, MouseEvent> | undefined,
        value: {
            ferie: AnsattPanelFerieEntry;
        }
    ) => {
        if (e) {
            if (toDelete.includes(value.ferie.Id)) {
                setToDelete(toDelete.filter((id) => id !== value.ferie.Id));
            } else {
                setToDelete([...toDelete, value.ferie.Id]);
            }
        }
    };

    const handleSaveClick = async () => {
        setSaving(true);
        try {
            if (!employeeId) return setSaving(false);
            if (updates.length > 0) {
                const res = await behandleOnskeRequest({
                    AnsattId: employeeId,
                    FerieOnsker: updates,
                });
                if (res.ok) {
                    const countApproved = updates.filter((update) => update.Status === OnskeStatus.Approved).length;
                    const countRejected = updates.filter((update) => update.Status === OnskeStatus.Rejected).length;
                    let message = '';
                    let color;
                    if (countApproved > 0 && countRejected > 0) {
                        message = `Leder har godkjent ${countApproved} og avslått ${countRejected} ferieønsker`;
                        color = theme.palette.primary.main;
                    } else if (countApproved > 0) {
                        message = `Leder har godkjent ${countApproved} ferieønsker`;
                        color = theme.palette.success.main;
                    } else if (countRejected > 0) {
                        message = `Leder har avslått ${countRejected} ferieønsker`;
                        color = theme.palette.error.main;
                    }

                    const date = new Date(
                        ferieInfo
                            ?.find((entry) => entry.FerieEntries.some((ferie) => ferie.Id === updates[0].Id))
                            ?.FerieEntries.find((ferie) => ferie.Id === updates[0].Id)?.Date ?? new Date()
                    );

                    await postNotification({
                        Message: message,
                        Color: color,
                        Url: `/plan?visning=uke&dato=${format(
                            date,
                            'yyyy-MM-dd'
                        )}&ferie=true&ansatt=${employeeId}&avdeling=${employee?.AvdelingId}`,
                        Targets: {
                            Users: [employeeId.toString()],
                        },
                        Channel: 'onsker',
                        SubChannel: 'ferie',
                    });
                }
                setUpdates([]);
            }

            if (toDelete.length > 0) {
                const payload: DeleteOnskePayload = {
                    OnskeIds: toDelete,
                };
                await deleteOnskeRequest(payload);
                setToDelete([]);
            }
        } catch (e) {
            queryClient.invalidateQueries(cacheKey);
            setMutateData(ferieInfo);
            console.error('Error while saving vacation request:');
        }
        setSaving(false);
    };

    const filteredFerie = filterBehandletFerie(mutateData, visBehandlede, updates, toDelete);

    return (
        <Box display="flex" flexDirection="column" height={'100%'} margin={'15px'} mt={'10px'}>
            <Year />
            <Divider sx={{ width: '50%', mb: '10px', placeSelf: 'center' }} />
            {isInitialLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width={'100%'}>
                    <CircularProgress />
                </Box>
            ) : mutateData && mutateData.length > 0 ? (
                <Box mb={'150px'}>
                    {filteredFerie.length === 0 ? (
                        <Box>
                            <Typography gridColumn={'1 / -1'} fontSize={14} textAlign={'center'}>
                                {p('no_vacation_requires_action')}
                            </Typography>
                            <Typography gridColumn={'1 / -1'} fontStyle={'italic'} fontSize={12} textAlign={'center'}>
                                {p('show_all_vacation_requests')}
                            </Typography>
                        </Box>
                    ) : (
                        <FerieBehandlingContext.Provider
                            value={{
                                updates,
                                setUpdates,
                                toDelete,
                                handleOnClick,
                                handleOnClickReset,
                                handleOnClickDelete,
                            }}
                        >
                            <GroupToggle
                                entries={filteredFerie.flatMap((entry) => entry.FerieEntries)}
                                title="Behandle alle"
                            />
                            {filteredFerie.map((entry) => {
                                return (
                                    <Box
                                        key={entry.Uke}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        width={'100%'}
                                        sx={{
                                            transition: 'all 0.3s ease',
                                        }}
                                        mb={'10px'}
                                    >
                                        <Typography
                                            fontSize={14}
                                            fontWeight={800}
                                            onClick={() => {
                                                window.open(
                                                    `https://${location.hostname}/plan?visning=uke&dato=${format(
                                                        new Date(entry.FerieEntries[0].Date),
                                                        'yyyy-MM-dd'
                                                    )}&ferie=true&ansatt=${employee?.Id}&avdeling=${
                                                        employee?.AvdelingId
                                                    }`,
                                                    '_self'
                                                );
                                            }}
                                            sx={{ cursor: 'pointer' }}
                                        >
                                            {p('week')} {entry.Uke}
                                        </Typography>
                                        <Divider sx={{ width: '20%', mb: '10px', placeSelf: 'center' }} />

                                        {entry.FerieEntries.length > 0 && (
                                            <>
                                                <GroupToggle entries={entry.FerieEntries} title="Behandle uke" />
                                                {entry.FerieEntries.map((ferie) => (
                                                    <FerieBehandlingDataRow key={ferie.Date} ferie={ferie} />
                                                ))}
                                            </>
                                        )}
                                    </Box>
                                );
                            })}
                        </FerieBehandlingContext.Provider>
                    )}

                    <FerieBehandlingInfo
                        handleSaveClick={handleSaveClick}
                        setVisBehandlede={setVisBehandlede}
                        disabled={updates.length === 0 && toDelete.length === 0}
                        loading={saving}
                    />
                </Box>
            ) : (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                >
                    <i>{p('no_vacation_requests')}</i>
                    <FerieBehandlingInfo
                        handleSaveClick={handleSaveClick}
                        setVisBehandlede={setVisBehandlede}
                        disabled={updates.length === 0 && toDelete.length === 0}
                        loading={saving}
                    />
                </Box>
            )}
        </Box>
    );
};
