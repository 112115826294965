import { createContext, useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { usePhrases } from '@tidsbanken/phrases';
import { useAtomValue, useSetAtom } from 'jotai';
import { AnsattPanelFerie, Fridag, OnskeStatus, VacationNumbersResponse } from '$helpers/types';
import useFerieInfo, { rootCacheKey } from '$hooks/useFerieInfo';
import useFerieRemaining from '$hooks/useFerieRemaining';
import useHolidays from '$hooks/useFridag';
import { FerieBehandlingTab } from './behandling/FerieBehandlingTab';
import { FerieInnstillingerTab } from './innstillinger/FerieInnstillingerTab';
import { FerieStatusTab } from './status/FerieStatusTab';

import { drawerStateAtom } from '~src/contextsAndAtoms/DrawerAtoms';
import { ferieTabStateAtom, isInitialLoadingAtom } from '~src/contextsAndAtoms/FerieAtoms';
import { SignalRContext } from '~src/contextsAndAtoms/SignalREventHubContext';

export enum FerieTabEnum {
    Status = 0,
    Onsker = 1,
    Innstillinger = 2,
}

export type FerieContextType = {
    ferieInfo: AnsattPanelFerie[] | undefined;
    remainingFerie: VacationNumbersResponse | undefined;
    fridager: Fridag[] | undefined;
};
export const FerieContext = createContext<FerieContextType>({
    ferieInfo: undefined,
    remainingFerie: undefined,
    fridager: undefined,
});

export const FerieTab = ({
    openSubTab,
    setOpenSubTab,
}: {
    openSubTab: FerieTabEnum;
    setOpenSubTab: (ferieTab: FerieTabEnum) => void;
}) => {
    const [activeTab, setActiveTab] = useState<FerieTabEnum>(openSubTab);
    const handleOnChange = (e: React.SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
        setOpenSubTab(FerieTabEnum.Status);
    };

    useEffect(() => {
        setActiveTab(openSubTab);
    }, [openSubTab]);

    const queryClient = useQueryClient();
    const p = usePhrases('meny');
    const { hasAdminOrAvdLederAccess, hasFerieAccess, databaseId, employeeId } = useAtomValue(drawerStateAtom);
    const setInitialLoading = useSetAtom(isInitialLoadingAtom);
    const { from, to, year } = useAtomValue(ferieTabStateAtom);

    const { ferieInfo, cacheKey, isLoading } = useFerieInfo({
        databaseId,
        employeeId: employeeId ?? 0,
        from,
        to,
    });

    const { fridager } = useHolidays({
        databaseId,
        employeeId: employeeId ?? 0,
        from,
        to,
    });

    const { vacationNumbers } = useFerieRemaining({
        databaseId,
        employeeId: employeeId ?? 0,
        date: `${year}-06-09`,
    });

    useEffect(() => {
        setInitialLoading(!isLoading && ferieInfo && fridager && vacationNumbers ? false : true);
    }, [vacationNumbers, ferieInfo, fridager, setInitialLoading, isLoading]);

    SignalRContext.useSignalREffect(
        'FerieonskeOnskeStatusChanged',
        (...args) => {
            const approvalChangedEvent = args[0] as { ids: number[]; approved: OnskeStatus };

            const ferieEntries = ferieInfo?.flatMap((fi) => fi.FerieEntries);

            const changedEntries = ferieEntries?.filter((fe) => approvalChangedEvent.ids.includes(fe.Id));
            if (changedEntries?.length && changedEntries.length > 0) {
                queryClient.invalidateQueries(rootCacheKey);
            }
        },
        [ferieInfo, queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieonskerCreated',
        () => {
            queryClient.invalidateQueries(rootCacheKey);
        },
        [queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieonskerDeleted',
        (...args) => {
            const deletedIds = args[0] as number[];

            const ferieEntries = ferieInfo?.flatMap((fi) => fi.FerieEntries);

            const deletedEntries = ferieEntries?.filter((fe) => deletedIds.includes(fe.Id));
            if (deletedEntries?.length && deletedEntries.length > 0) {
                queryClient.invalidateQueries(rootCacheKey);
            }
        },
        [ferieInfo, queryClient, cacheKey]
    );

    SignalRContext.useSignalREffect(
        'FerieEndrePlanChanged',
        () => {
            queryClient.invalidateQueries(rootCacheKey);
        },
        [queryClient, cacheKey]
    );
    return hasFerieAccess ? (
        <Box display="flex" flexDirection="column" margin={'10px'} marginTop={'5px'}>
            <Tabs
                value={activeTab}
                onChange={handleOnChange}
                sx={{
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'space-evenly',
                        width: '100%',
                    },
                }}
            >
                <Tab sx={{ fontSize: '12px', fontWeight: '700' }} label={p('status_header')} />
                {hasAdminOrAvdLederAccess ? (
                    <Tab sx={{ fontSize: '12px', fontWeight: '700' }} label={p('process_header')} />
                ) : null}
                {hasAdminOrAvdLederAccess ? (
                    <Tab sx={{ fontSize: '12px', fontWeight: '700' }} label={p('settings_header')} />
                ) : null}
            </Tabs>
            <Grid container direction="column" spacing={0} height={'100%'}>
                <Grid flexGrow={1}>
                    <FerieContext.Provider
                        value={{
                            ferieInfo,
                            remainingFerie: vacationNumbers,
                            fridager,
                        }}
                    >
                        {
                            {
                                [FerieTabEnum.Status]: <FerieStatusTab />,
                                [FerieTabEnum.Onsker]: hasAdminOrAvdLederAccess ? <FerieBehandlingTab /> : null,
                                [FerieTabEnum.Innstillinger]: hasAdminOrAvdLederAccess ? (
                                    <FerieInnstillingerTab />
                                ) : null,
                            }[activeTab]
                        }
                    </FerieContext.Provider>
                </Grid>
            </Grid>
        </Box>
    ) : (
        <Box display="flex" flexDirection="column" margin={'15px'}>
            <Typography fontStyle={'italic'} fontSize={14}>
                {p('vacation_tab_no_access')}
            </Typography>
        </Box>
    );
};
