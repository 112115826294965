import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Fridag } from '$helpers/types';
import { getHolidays } from '../helpers/api';

export const rootCacheKey = ['fridagInfo'];

type Props = {
    databaseId: string;
    employeeId: number;
    from: Date;
    to: Date;
};

type ReturnType = {
    fridager: Fridag[];
    isLoading: boolean;
    cacheKey: (string | { databaseId: string; employeeId: number; from: Date; to: Date })[];
    error: Error | null;
};
const useHolidays = ({ databaseId, employeeId, from, to }: Props): ReturnType => {
    const cacheKey = [...rootCacheKey, { databaseId, employeeId, from, to }];

    const { data, isLoading, isFetching, error } = useQuery({
        queryKey: cacheKey,
        queryFn: () => getHolidays(from, to),
        enabled: true,
        refetchOnMount: 'always',
        placeholderData: keepPreviousData,
    });

    const fridager = data ?? [];

    return {
        fridager,
        isLoading: isLoading || isFetching,
        cacheKey,
        error,
    };
};

export default useHolidays;
